//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//Purpose: Implement styles needed to render the cases-won component

.cases-won{
    width: auto;
    display: inline-block;
    .number{
	@extend %yellow;
	font-family: adelle_sans_semibold;
	font-size: px2em(96);
  line-height: 100px;
	padding-bottom: 18px;
	@extend %grey3-bbc;
	border-bottom-style: dotted;
	border-bottom-width: 1px;
	margin-bottom: 24px;
    }

    .subheading{
	font-family: adelle_sans_semibold;
	@extend %grey-dark2;
	font-size: px2em(30);
    }
}
