.personal-injury-overview{

  h1 {
    font-family: 'vollkorn_regular', Georgia, serif;
    font-size: px2em(50);
    line-height: 54px;
    @media only screen and (min-width: 480px) {
      font-size: px2em(56);
      line-height: 60px;
    }
  }

  .subheading{
    @extend %paragraph-2;
    @extend %grey-dark2;
    text-align: center;
    padding-bottom: 6px;
    font-size: px2em(20);
    line-height: 26px;
    font-family: 'vollkorn_regular', Georgia, serif;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 480px) {
      padding-bottom: 58px;
    }
  }

  .lead-text{
    @extend %paragraph-2;
    @extend %grey-dark2;
    font-size: px2em(20);
    line-height: 26px;
    font-family: 'vollkorn_regular', Georgia, serif;
  }

  .body-text{
    font-family: 'proxima_nova_regular';
    @extend %grey-dark;
    font-size: px2em(15);
    margin-bottom: 0 !important;
    @media only screen and (min-width: 768px) {
      max-width: 480px;
      margin-bottom: 35px !important;
    }
    p {
      font-family: 'proxima_nova_regular';
      margin-bottom: 19px;
    }
  }

  .spacer{
    width: 100%;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 42px;
    @extend %grey-lighter2;
    @media only screen and (min-width: 640px) {
      margin-top: 54px;
      margin-bottom: 0;
    }

    .inner-space{
      margin-left: auto;
      margin-right: auto;
      height: 1px;
      border-bottom: 1px;
      border-style: solid;

    }
  }

  .contact-us-bar{
    @extend %grey-bg;
    margin-bottom: 48px;
    @media only screen and (min-width: 480px) {
      margin-bottom: 96px;
    }
    .contact-us-contents{
      margin-left: 6%;

      .text-button{
        display: inline-block;
        width: 100%;
        @media only screen and (min-width: 640px) {
          width: 48%;
        }

        .headline{
          font-family: vollkorn_regular;
          @extend %white;
          font-size: px2em(45);
          line-height: 48px;
          margin-top: 40px;
          margin-bottom: 48px;
          text-transform: capitalize;
          @media only screen and (min-width: 480px) {
            margin-top: 88px;
          }
          @media only screen and (min-width: 768px) {
            max-width: 480px;
          }
        }

        .blurb{
          font-family: vollkorn_regular;
          @extend %grey-dark3;
          font-size: px2em(16);
          margin-bottom: 48px;
        }

        .button-container{
          text-align: center;
          margin-bottom: 48px;

          .button{
            @extend %blue-bg;
            margin-top: 22px;
            width: 183px;
            height: 36px;
            text-transform: uppercase;
            text-align: center;
            font-size: px2em(14);
            &:hover {
              cursor: pointer;
              background: #3f3f3f;
            }

            a{
              width: 183px;
              height: 36px;
              line-height: 36px;
              @extend %white;
            }
          }
        }
      }

      .image-container{
        vertical-align: bottom;
        width: 100%;
        display: inline-block;
        vertical-align: top;
        @media only screen and (min-width: 640px) {
          width: 50%;
        }
        img {
          max-width: 450px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
