.faq {
  h1 {
    font-size: px2em(56);
    line-height: 60px;
    padding-bottom: 16px;
  }
  p {
    font-size: px2em(20);
    line-height: 26px;
    font-family: 'vollkorn_regular', Georgia, serif;
    color: #3f3f3f;
    padding-bottom: 6px;
  }

  .faq-banner-contents {
    .left-side {
      width: 100%;
      @media only screen and (min-width: 768px) {
        width: 48%;
      }
    }
    .headline {
      font-size: px2em(45);
      line-height: 48px;
      font-family: 'vollkorn_regular', Georgia, serif;
      width: 100%;
    }
    .right-side {
      position: relative;
      top: 6px;
      width: 100%;
      @media only screen and (min-width: 768px) {
        width: 48%;
      }
    }
  }
}
