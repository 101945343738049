//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//Purpose: Implements the styles required for the article post pages

.single-post{
  .button-outer-class {
    padding-top: 10px;
  }
  .right-side-bar {
    margin-top: 22px !important;
    .vc_col-sm-8, .vc_col-sm-7 {
      width: 100%;
      @media only screen and (min-width: 1024px) {
        width: 66.6666667%;
      }
    }
    .vc_col-sm-3, .vc_col-sm-4 {
      width: 100%;
      @media only screen and (min-width: 1024px) {
        width: 25%;
      }
    }
  }

  .article-headline{
    width: 100%;
    margin-bottom: 2px;
    font-family: 'vollkorn_regular', Georgia, serif;
    font-size: px2em(50);
    line-height: 54px;
    @media only screen and (min-width: 480px) {
      font-size: px2em(56);
      line-height: 60px;
    }
    & + .wpb_single_image {
      margin-top: 38px !important;
      margin-bottom: 43px !important;
    }
  }

  .article-image{
    width: 100%;
    img {
      width: 100%;
    }
  }

  .datecat{
    .date, .category{
      font-family: 'proxima_nova_regular', arial, sans-serif;
      font-size: px2em(15);
      line-height: px2em(19);
      text-transform: uppercase;
    }
    .category {
      a {
        font-family: 'proxima_nova_regular', arial, sans-serif;
        color: #3f3f3f;
        &:hover {
          color: #000;
        }
      }
    }
  }

  .lead-text{
    font-family: 'vollkorn_regular';
    @extend %grey-dark2;
    font-size: px2em(20);
    line-height: 26px;
    margin: 12px 0 !important;
    a {
      font-family: 'vollkorn_regular';
      @extend %grey-dark2;
      font-size: px2em(16);
      line-height: 26px;
    }
  }

  .article-body {
    font-family: 'proxima_nova_regular', arial, sans-serif;
    @extend %grey-dark;
    font-size: px2em(15);
    line-height: 21px;
    margin-top: 16px !important;
    @media only screen and (min-width: 480px) {
      padding-bottom: 42px;
    }
    ul {
      list-style: disc;
    }
    ol {
      list-style: decimal;
    }
    p {
      margin-bottom: 15px;
      a {
        text-decoration: underline;
        &:hover {
          @extend %blue;
        }
      }
    }
    a {
      font-family: 'proxima_nova_regular', arial, sans-serif;
      font-size: px2em(16);
      @extend %grey-dark;
      text-decoration: underline;
      &:hover {
        @extend %blue;
      }
    }
  }

  .contact-us-banner{
    @extend %yellow-bg;

    .contact-us-contents{

      .headline{
        @extend %white;
        font-family: 'vollkorn_regular', Helvetica, sans-serif;
        font-size: px2em(33);
        line-height: 38px;
        margin-top: 38px;
        margin-bottom: 16px;
        text-align: center;
        text-transform: capitalize;
        @media only screen and (min-width: 640px) {
          margin-left: auto;
          margin-right: auto;
          width: 66%;
        }
      }

      .blurb{
        @extend %white;
        font-family: 'adelle_sans_regular';
        text-align: center;
        line-height: 24px;
        font-size: px2em(16);
        padding-bottom: 18px;
        @media only screen and (min-width: 640px) {
          margin-left: auto;
          margin-right: auto;
          width: 48%;
        }
      }

      .button-container{
        width: 66%;
        text-align: center;
        margin-bottom: 48px;
        margin-left: auto;
        margin-right: auto;

        .button{
          @extend %blue-bg;
          margin-top: 22px;
          width: 183px;
          height: 36px;
          text-transform: uppercase;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          &:hover {
            cursor: pointer;
            background: #3f3f3f;
          }
          a{
            font-family: 'adelle_sans_semibold', arial, sans-serif;
            width: 183px;
            height: 36px;
            line-height: 36px;
            font-size: px2em(14);
            @extend %white;
          }
        }
      }
    }
  }
}
