/*
* Bootstrap Mixin for breakpoints
* https://medium.comdeveloping-with-sass/creating-a-dead-simple-sass-mixin-to-handle-responsive-breakpoints-889927b37740#.dt7ray8bg
*/

@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);

  @if $type == string {
    @if $min == xs {
      @media (max-width: 543px) { @content; }
    }

    @else if $min == sm {
      @media (min-width: 544px) { @content; }
    }

    @else if $min == md {
      @media (min-width: 768px) { @content; }
    }

    @else if $min == lg {
      @media (min-width: 922px) { @content; }
    }

    @else if $min == xl {
      @media (min-width: 1200px) { @content; }
    }

    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
    }
  }

  @else if $type == number {
    $query: "all" !default;
    @if $min != 0 and $max != 0 {
      $query: "(min-width: #{$min}) and (max-width: #{$max})";
    }
    @else if $min != 0 and $max == 0 {
      $query: "(min-width: #{$min})";
    }
    @else if $min == 0 and $max != 0 {
      $query: "(max-width: #{$max})";
    }
    @media #{$query} {
      @content;
    }
  }

}
