.singular {
  .breadcrumbs {
  }
  .top-content {
    .blurb-text-block {
      padding: 28px 0 58px;
    }
  }
  .category-select-container {
    border-top: 0;
    padding-top: 0;
  }
  input {
    &[type='search'] {
      color: #000;
      height: 38px;
      border: 1px solid #f0f0f0;
      line-height: 38px;
      font-size: px2em(14);
      font-family: 'adelle_sans_regular', arial, sans-serif;
      padding-left: 12px;
      background: url('../images/search-icon-mobile.svg') no-repeat;
      background-position: calc(100% - 12px);
    }
  }
  .paging-buttons {
    padding-bottom: 95px;
  }
}
