//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//Purpose: Implements styles needed for the contact-us page

.contact {
  @media only screen and (max-width: 479px) {
    .evbreadcrumbs {
      margin-top: 6px;
    }
  }
}

.contact, .our-firm {

  .wpb_gmaps_widget {
    margin-bottom: 0;
    .wpb_wrapper {
      padding: 0;
    }
  }

  .address-block {

    p {
      margin-bottom: 0;
      color: #3f3f3f;
      &:hover {
        color: black;
      }
    }
    a {
      font-family: 'vollkorn_regular';
      font-size: 16px;
      color: #3f3f3f;
      @media only screen and (min-width: 640px) {
        font-size: 20px;
      }
      &:hover {
        color: black;
      }
    }
  }



  h2 {
    width: 75%;
    font-size: px2em(34);
    line-height: 37px;
    font-family: 'vollkorn_regular', Georgia, serif;
    padding-bottom: 24px;
    @media only screen and (min-width: 640px) {
      font-size: px2em(40);
      line-height: 43px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: px2em(45);
      line-height: 48px;
    }
  }

  .contact-us-left {
    p {
      font-size: px2em(16);
      line-height: 24px;
      font-family: 'vollkorn_regular', Georgia, serif;
      color: #3f3f3f;
      @media only screen and (min-width: 1024px) {
        font-size: px2em(20);
        line-height: 30px;
      }
    }
  }

  .contact-us-right {
    @extend %grey-dark;
    padding-top: 30px;
    font-size: px2em(15);
    line-height: 21px;
    font-family: 'proxima_nova_regular', Arial, sans-serif;
    .inner-link-class {
      padding-bottom: 36px;
      @media only screen and (min-width: 768px) {
        padding-bottom: 0;
      }
    }
  }

    .spacer{
	width: 100%;
	text-align: center;
	margin-top: 4px;
	margin-bottom: 36px;
	@extend %grey-lighter2;

	.inner-space{
	    margin-left: auto;
	    margin-right: auto;
	    width: 100%;
	    height: 1px;
	    border-bottom: 1px;
	    border-style: solid;

	}
    }

    .accessible-service-plan{
	@extend %yellow-bg;
	@extend %white;
  padding-top: 18px;

  .vc_column-inner {
    padding-left: 0;
    padding-right: 0;
  }

	h2{
    font-size: px2em(33);
    line-height: 38px;
    font-family: 'vollkorn_regular', Georgia, serif;
	    width: 100%;
	    text-align: center;
      padding-bottom: 0;
      margin-bottom: 7px;
      & + .wpb_text_column {
        margin-bottom: 20px;
      }
	}

	p{
	    @extend %caption-1;
	    text-align: center;
	    margin-left: auto;
	    margin-right: auto;
      font-size: px2em(16);
      line-height: 24px;
      font-family: 'adelle_sans_regular';
      font-weight: 100;
      @media only screen and (min-width: 640px) {

  	    width: 66%;
      }
	}

	.accessible-plan-link{
	    text-align: center;
	    margin-bottom: 48px;

	    .inner-link-class{
		@extend %blue-bg;
		@extend %white;
		width: 139px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		margin-right: auto;
		margin-left: auto;
    &:hover {
      cursor: pointer;
    }

		a{
		    @extend %white;
		}
	    }
	}

	.accessible-plan-link{
	    .inner-link-class:hover{
		background-color: #3f3f3f;
	    }
	}
    }

    .practice-areas-nav-section {
      h2 {
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 5px;
        & + .wpb_text_column {
          margin-bottom: 10px;
        }
      }
      .vc_col-sm-6 {
        &:first-of-type {
          padding-bottom: 40px;
          @media only screen and (min-width: 767px) {
            padding-bottom: 0;
          }
        }
      }
    }
}
