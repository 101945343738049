.category {
  h1 {
    @media only screen and (min-width: 640px) {
      padding-bottom: 38px;
    }
  }

  .evbreadcrumbs, .filters, .top-content {
    @media only screen and (min-width: 640px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .blurb-text-block {
    padding-top: 28px;
    font-family: 'proxima_nova_regular', arial, sans-serif;
    font-size: px2em(15);
    line-height: 21px;
    color: grey;
  }
  .article-search {
    margin-top: 0;
    margin-bottom: 0;
  }
  .category-select-container {
    padding-top: 0;
    border-top: 0;
    margin-bottom: 24px;
    margin-right: 66px;
    @media only screen and (min-width: 640px) {
      margin-bottom: 48px;
    }
  }
}
