// Glyphicons font path
$font-path:        "../fonts/";
$icon-font-path: $font-path;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Assumed pixel base for px->em calculations
$font-size-base: 16px;
body {
  font-size: 16px;
}
h1, h2, h3, h4, h5, h6 {
  font-size: px2em(16);
}

// colour map
$colours: (
    white:                 #FFFFFF,
    black:                 #000000,
    yellow:                #E7BC36,
    yellow2:               #E6BB35,
    blue:                  #1F93C3,
    grey:                  #BDCCD4,
    grey2:                 #666666,
    grey3:                 #929493,
    grey-midlight:         #949494,
    grey-light:            #f0f0f0,
    grey-lightest:         #E6E6E6,
    grey-lighter2:         #D9D9D9,
    grey-lighter:          #F2F2F2,
    grey-lightest2:        #F0F0F0,
    grey-dark2:            #3F3F3F,
    grey-dark3:            #535353,
    grey-dark:             #808080,
    grey-darker:           #404040,
);

//vestigial defines
$white:       map_get($colours, white);
$black:       map_get($colours, black);
$yellow:      map_get($colours, yellow);
$blue:        map_get($colours, blue);
$grey-dark:   map_get($colours, grey-dark);
$grey-darker: map_get($colours, grey-darker);
$grey-light:  map_get($colours, grey-light);

// Bootstrap Over-rides
$brand-primary:         map_get($colours, yellow);
$gray-darker:           map_get($colours, grey-darker);
$gray-dark:             map_get($colours, grey-dark);
$gray:                  map_get($colours, grey);
$gray-light:            map_get($colours, grey-light2);
$gray-lighter:          map_get($colours, grey-lighter);
$text-color:            map_get($colours, black);

// typeography map
$fonts: (

    proxima-nova-regular: ('Proxima Nova Regular', ( ('opentype', 'proximanova-regular.otf'),
						     ('truetype', 'proximanova-regular.ttf'),
						     ('woff2',    'proximanova-regular.woff2'),
						     ('woff',     'proximanova-regular.woff'),
						     ('svg',      'proximanova-regular.svg'),
						     ('embedded-opentype', 'proximanova-regular.eot'),
						   )
			  ),
    adelle-sans-semibold: ('Adelle Sans SemiBold', ( ('opentype', 'adelle_sans_semibold.otf'),
						     ('truetype', 'adelle_sans_semibold.ttf'),
						     ('woff2',    'adelle_sans_semibold.woff2'),
						     ('woff',     'adelle_sans_semibold.woff'),
						     ('svg',      'adelle_sans_semibold.svg'),
						     ('embedded-opentype', 'adelle_sans_semibold.eot'),
						   )
			  ),
    adelle-sans-regular:  ('Adelle Sans Regular', ( ('opentype', 'adelle_sans_regular.otf'),
						    ('truetype', 'adelle_sans_regular.ttf'),
						    ('woff2',    'adelle_sans_regular.woff2'),
						    ('woff',     'adelle_sans_regular.woff'),
						    ('svg',      'adelle_sans_regular.svg'),
						    ('embedded-opentype', 'adelle_sans_regular.eot'),
						  )
			  ),
    adelle-sans-bold:     ('Adelle Sans Bold', ( ('opentype' 'adelle_sans_bold.otf'),
						 ('truetype', 'adelle_sans_bold.ttf'),
						 ('woff2',    'adelle_sans_bold.woff2'),
						 ('woff',     'adelle_sans_bold.woff'),
						 ('svg',      'adelle_sans_bold.svg'),
						 ('embedded-opentype', 'adelle_sans_bold.eot'),
					       )
			  ),
);

// Links
$link-hover-decoration: none;

//NOTE: font classes
// typeography context map
$fontContext: (
    headings:           nth(map_get($fonts, volkorn-regular), 1),
    body:               nth(map_get($fonts, proxima-nova-regular), 1),
    callout1:           nth(map_get($fonts, volkorn-regular), 1),
    callout2:           nth(map_get($fonts, adelle-sans-semibold), 1),
    paragraph124:       nth(map_get($fonts, volkorn-regular), 1),
    paragraph3:         nth(map_get($fonts, proxima-nova-regular), 1),
    link:               nth(map_get($fonts, adelle-sans-semibold), 1),
    nav-item:           nth(map_get($fonts, adelle-sans-regular), 1),
    caption:            nth(map_get($fonts, adelle-sans-regular), 1),
    carousel-subheader: nth(map_get($fonts, adelle-sans-semibold), 1),
    breadcrumbs:        nth(map_get($fonts, adelle-sans-regular), 1),
    copyright-footer:   nth(map_get($fonts, proxima-nova-regular), 1),
);

$headings-font-family: map_get($fonts, volkorn-regular);
$body-font-family:     map_get($fonts, proxima-nova-regular);
$callout1-font-family: map_get($fonts, volkorn-regular);
$callout2-font-family: map_get($fonts, adelle-sans-semibold);
$paragraph-style124-font-family:   map_get($fonts, volkorn-regular);
$paragraph-style3-font-family:     map_get($fonts, proxima-nova-regular);
$link-font-family:     map_get($fonts, adelle-sans-regular);
$nav-item-font-family: map_get($fonts, adelle-sans-regular);
$caption-font-family:  map_get($fonts, adelle-sans-regular);
$carousel-subheader-font-family:   map_get($fonts, adelle-sans-semibold);
$dropdown-menu-text-font-family: map_get($fonts, adelle-sans-regular);
$breadcrumbs-font-family:  map_get($fonts, adelle-sans-regular);
$copyright-footer-font-family: map_get($fonts, adelle-sans-regular);

// breakpoint map for using with the bp function
$breakpoints: (
    'xs' : 320px,
    'sm' : 480px,
    'md' : 640px,
    'lg' : 768px,
);


@font-face {
    font-family: 'adelle_sans_regular';
    src: url('../fonts/adelle_sans/adelle_sans_regular-webfont.eot');
    src: url('../fonts/adelle_sans/adelle_sans_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.woff2') format('woff2'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.woff') format('woff'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.ttf') format('truetype'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.svg#adelle_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'adelle_sans_semibold';
    src: url('../fonts/adelle_sans/adelle_sans_regular-webfont.eot');
    src: url('../fonts/adelle_sans/adelle_sans_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.woff2') format('woff2'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.woff') format('woff'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.ttf') format('truetype'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.svg#adelle_sansregular') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'adelle_sans_bold';
    src: url('../fonts/adelle_sans/adelle_sans_regular-webfont.eot');
    src: url('../fonts/adelle_sans/adelle_sans_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.woff2') format('woff2'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.woff') format('woff'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.ttf') format('truetype'),
         url('../fonts/adelle_sans/adelle_sans_regular-webfont.svg#adelle_sansregular') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'vollkorn_regular';
    src: url('../fonts/vollkorn/vollkorn-regular-webfont.eot');
    src: url('../fonts/vollkorn/vollkorn-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/vollkorn/vollkorn-regular-webfont.woff2') format('woff2'),
         url('../fonts/vollkorn/vollkorn-regular-webfont.woff') format('woff'),
         url('../fonts/vollkorn/vollkorn-regular-webfont.ttf') format('truetype'),
         url('../fonts/vollkorn/vollkorn-regular-webfont.svg#vollkornregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_regular';
    src: url('../fonts/proxima_nova/proxima-regular.eot');
    src: url('../fonts/proxima_nova/proxima-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima_nova/proxima-regular.woff2') format('woff2'),
         url('../fonts/proxima_nova/proxima-regular.woff') format('woff'),
         url('../fonts/proxima_nova/proxima-regular.ttf') format('truetype'),
         url('../fonts/proxima_nova/proxima-regular.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'glyphicons';
    src: url('../fonts/glyphicons/glyphicons-halflings-regular.eot');
    src: url('../fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/glyphicons/glyphicons-halflings-regular.woff2') format('woff2'),
         url('../fonts/glyphicons/glyphicons-halflings-regular.woff') format('woff'),
         url('../fonts/glyphicons/glyphicons-halflings-regular.ttf') format('truetype'),
         url('../fonts/glyphicons/glyphicons-halflings-regular.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Creates a list of static expressions or media types for mixin
// $media-expressions: (
//   'screen': 'screen',
//   'print': 'print',
//   'handheld': 'handheld',
//   'landscape': '(orientation: landscape)',
//   'portrait': '(orientation: portrait)',
//   'retina2x': '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
//   'retina3x': '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi)'
// );

/*********************
PLACEHOLDERS
*********************/

%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
