//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//Purpose: implement styles for the various page navigation text sections

.buckets-2 {
  @media only screen and (min-width: 768px) {
    margin-top: 48px;
    margin-bottom: 28px;
  }
}

.overview-faq{
  padding-bottom: 0;
  @media only screen and (min-width: 768px) {
    padding-bottom: 42px;
  }
    .header-text{
	font-size: px2em(24);
	line-height: px2em(30);
	text-transform: capitalize;
	margin-top: 0px;
	@extend %black;
    }

    .blurb-text{
	@extend %grey-dark;
	font-family: 'proxima_nova_regular', arial, sans-serif;
	font-size: px2em(15);
    }

    a{
	@extend %text-links-1;
	@extend %blue;
    }

}

.contact{

  .copy-nav-container {
    padding-bottom: 0;
  }
    img{
    }

    .header-text{
	font-size: px2em(24);
	line-height: px2em(30);
	text-transform: capitalize;
	margin-top: 0px;
	@extend %black;
    }

    .blurb-text{
	@extend %grey-dark;
	font-family: 'proxima_nova_regular', arial, sans-serif;
	font-size: px2em(15);
    }

    a{
	@extend %text-links-1;
	@extend %blue;
  font-size: px2em(16);
  font-family: 'adelle_sans_semibold';
  &:hover {
    color: #3f3f3f;
  }
    }

    .accessible-plan-link {
      a {
        font-size: px2em(14);
      }
    }

}

.meet-the-team{
    margin-top: 49px;

    img{
    }

    .header-text{
	font-size: px2em(24);
	line-height: px2em(30);
	text-transform: capitalize;
	@extend %black;
	margin-top: 0px;
    }

    .blurb-text{
	@extend %grey-dark;
	font-family: 'proxima_nova_regular', arial, sans-serif;
	font-size: px2em(15);
    }

    a{
	@extend %text-links-1;
	@extend %blue;
    }

}

.practice-areas-nav-section{
  padding: 48px 0 40px;
  @media only screen and (min-width: 640px) {
    padding: 96px 0 88px;
  }
    img{
    }

    .header-text{
	font-size: px2em(24);
	line-height: px2em(30);
	text-transform: capitalize;
	@extend %black;
	margin-top: 0px;
    }

    .blurb-text{
	@extend %grey-dark;
	font-family: 'proxima_nova_regular', arial, sans-serif;
	font-size: px2em(15);
    }

    a{
	@extend %text-links-1;
	@extend %blue;
    }
}
