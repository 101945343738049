.error404, .search, .search-no-results {
  h2 {
    a {
      font-size: 20px;
      line-height: 26px;
      font-family: 'vollkorn_regular', Georgia, serif;
      color: #3f3f3f;
      &:hover {
        color: #000;
      }
    }
  }
  .content {
    &.row {
      padding-bottom: 48px;
      @media only screen and (min-width: 640px) {
        padding-bottom: 96px;
      }
    }
  }
  .alert-warning {
    background-color: transparent;
    border: 0;
    color: #3f3f3f;
    font-family: 'proxima_nova_regular', arial, sans-serif;
    padding-left: 0;
  }
  time {
    color: #3f3f3f;
    font-family: 'proxima_nova_regular', arial, sans-serif;
    padding-bottom: 4px;
  }
  .entry-summary, .author, .nav-previous {
    font-family: 'proxima_nova-regular', arial, sans-serif;
    font-size: px2em(15);
    line-height: 21px;
    color: grey;
    a {
      color: #1F93C3;
      &:hover {
        color: #3f3f3f;
      }
    }
  }
  input[type='search'] {
    @extend %form-element;
    display: inline-block;
    width: 227px;
    background-repeat: no-repeat;
    background-position: 196px;
    margin-bottom: 0px;
    position: relative;
    box-shadow: none;
  }
  input[type='submit'] {
    color: transparent;
    width: 32px;
    height: 32px;
    background-position: center center;
    background-color: transparent;
    background-size: 16px 16px;
    border: 0;
    position: absolute;
    left: 209px;
    bottom: 1px;
    &:hover {
      background-position: center center;
      background-color: white;
      border: 0;
      background-size: 16px 16px;
      color: transparent;
    }
  }
}
