//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//Purpose: Implements SASS to generate the stylesheet rules for the question/answer blocks on the faq page
.question-block{
    width: 480px;
    @extend %white-bg;
    margin-bottom: 24px;
    &:hover {
      cursor: pointer;
    }

    .question {
    	width: 100%;
    	height: 40px;
    	@extend %white-bg;
      font-family: vollkorn;
      text-align: center;
      @media only screen and (min-width: 640px) {
        height: 48px;
      }

    	.button {
    	    @extend %blue-bg;
    	    @extend %white;
    	    width: 40px;
    	    height: 40px;
    	    float: left;
          @media only screen and (min-width: 640px) {
            height: 48px;
            width: 48px
          }
    	}

    	.closed{
    	    background-image: url('../images/plus.svg');
    	    background-repeat: no-repeat;
    	    background-position: center;
          background-size: 40%;
          @media only screen and (min-width: 640px) {
            background-size: 50%;
          }
    	}

    	.open{
    	    background-image: url('../images/minus.svg');
    	    background-repeat: no-repeat;
    	    background-position: center;
          background-size: 40%;
          @media only screen and (min-width: 640px) {
            background-size: 50%;
          }
    	}

    	.text{
    	    font-family: 'vollkorn_regular';
    	    @extend %grey-dark2;
    	    font-size: px2em(13);
    	    height: 40px;
    	    line-height: 40px;
    	    float: left;
          padding-left: 0;
          color: #3f3f3f;

          @media only screen and (min-width: 325px) and (max-width: 479px) {
            padding-left: 5px;
      	    font-size: px2em(13);
          }
          @media only screen and (min-width: 480px) {
            font-size: px2em(20);
            padding-left: 20px;
          }
          @media only screen and (min-width: 640px) {
            height: 48px;
            line-height: 48px;
          }
    	}
    }

    .answer{
    	@extend %blue-bg;
    	@extend %white;
    	padding: 25px;
    	font-size: px2em(15);
      line-height: 21px;
    	display: none;
      font-family: 'proxima_nova_regular';
      text-align: left;
    }
}
