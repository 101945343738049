@media print {
  @page {
    margin: 0cm;
  }
  a {
    &[href] {
      &:after {
        display: none;
      }
    }
  }

  body {
    overflow: visible;
    background-color: #f2f2f2 !important;
  }

  .main {
    padding: 0;
  }

  .wrap.container {
    padding: 0;
  }

  .vc_row {
    .vc_column-inner {
      padding: 0;
    }
  }

  .banner, .footer, .category-select-container, .recent-articles, .lawyer-profile-contact-form, .bottom-banner, .copyright, .links {
    display: none;
  }

  .logo {
    display: block !important;
  }

  .breadcrumbs {
    display: none;
  }

  .background-info {
    float: right;
  }

  .contact-info {
    &:before {
      // @include svg-png-bkg('logo');
      content: url('../images/logo.png');
      margin: 46px 0;
      background-size: auto;
      width: 206px;
      height: 56px;
      display: block;
    }
  }

  .lawyer-info {
    background-color: #fff !important;
    width: 100%;
    padding-bottom: 48px;
    padding-left: 0;
    padding-right: 0;
    .select-social {
      margin-bottom: 0;
    }
  }
  .lawyer-image, .contact-info, .background-info {
    margin-left: 15px;
    margin-right: 15px;
    width: 40%;
  }
  .background-info {
    margin-right: 30px;
  }
  .writeup-lead {
    margin-top: 20px !important;
    margin-bottom: 19px !important;
  }
  .lawyer-writeup-info {
    left: 168px;
    width: 80%;
    margin: 0 auto;
    max-width: 150%;
    overflow: visible;
    padding-top: 12px;
    padding-left: 30px;
    background-color: #f2f2f2 !important;
    .writeup-content {
      padding-bottom: 19px !important;
    }
    .vc_col-sm-8, .vc_col-md-5 {
      width: 41.666667%;
      clear: both;
    }
    .vc_col-sm-8 {
      p {
        font-size: 15px !important;
      }
    }
    .vc_col-md-5 {
      p {
        font-size: 14px !important;
      }
    }
    .vc_row {
      overflow: visible;
      .vc_single_image-wrapper {
        overflow: visible;
        z-index: 9999;
      }
    }
    .vc_col-md-7 {
      clear: both;
      position: fixed;
      bottom: 0;
      overflow: hidden;
    }
  }
  .background-info {
    position: relative;
    top: 146px;
    right: 48px;
  }
  .lawyer-writeup-info, .contact-info {
    float: left;
  }
  .contact-info {
    position: relative;
    left: 76px;
  }
}
