/* Copyright 2016 Evoke Solutions
*
* Author: Alex Lepsa
* Usage: @include px2em(<pixels>)
*
*/

@function px2em($pixels, $context: 16) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}
