// Include fonts
//@import url(https://fonts.googleapis.com/css?family=Vollkorn); // Vollkorn
//@import url(http://fast.fonts.net/cssapi/4c995927-4c1b-4239-8656-b846d4a27ea7.css); // Adelle Sans (fonts.com) - font-family:'Adelle Sans W01';


@font-face {
    font-family: 'Adelle Sans Bold';
    src: url(../fonts/adelle_sans_bold.otf);
}
@font-face {
    font-family: 'Adelle Sans Regular';
    src: url(../fonts/adelle_sans_regular.otf);
}

@font-face {
    font-family: 'Adelle Sans SemiBold';
    src: url(../fonts/adelle_sans_semibold.otf);
}

@font-face {
    font-family: 'Proxima Nova Regular';
    src: url(../fonts/proximanova-regular.otf);
}

@font-face {
    font-family: 'Vollkorn Regular';
    src: url(../fonts/volkorn-regular.otf);
}

%volkron {
  font-family: 'Vollkorn Regular';
}

%adelle-sans {
  font-family: 'Adelle Sans Regular';
}


//@include genFonts()

// Logo
%logo-header {
    @include svg-png-bkg('logo');
    display: block;
    width: 120px;
    height: 33px;
    background-size: 120px 33px;
    margin-top: 14px;
}

%logo-footer {
    display: block;
    width: 146px;
    height: 41px;
    @include svg-png-bkg('logo-bottom');
}

%h1 {
    @extend h1;
}

%h2 {
    @extend h2;
}

%h3 {
    @extend h3;
}

%h4 {
    @extend h4;
}

%h5 {
    @extend h5;
}

%h6 {
    @extend h6;
}

%h7 {
    font-size: px2em(20);
    line-height: px2em(18.8);
    text-transform: capitalize;
    font-family: $headings-font-family;
}

%body{
    @extend body;
}

.navigation-arrow{
    margin-left: 6px;
    @include svg-png-bkg('linkarrow');
    height: auto;
    background-position: center;
    width: 5px;
    display: inline-block;
    content: '';
}

button, button:visited, button:hover, button:focus, a, a:visited, a:hover, a:focus, input[type='text']:focus, input[type='search']:focus, textarea, textarea:focus {
  outline: none;
  border-color: #f0f0f0 !important;
  box-shadow: none !important;
}
