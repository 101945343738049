.search {
  h1 {
    word-break: break-word;
  }
  label {
    input {
      &.search-field {
        @include svg-png-bkg('search-icon');
        background-position: right 12px top 9px;
        background-size: 17px 17px;
        &:hover {
          @include svg-png-bkg('search-icon-mobile');
        }
      }
    }
  }
}
