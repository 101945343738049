/* Copyright 2016 Evoke Solutions

Author: Alan Dres

Implements the styles needed for the secure-contact form
*/

.secure-contact-form{

  padding-bottom: 28px;
    .left-section{
	width: 100%;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 27px;
	font-family: 'proxima_nova_regular', arial, sans-serif;
  @media only screen and (min-width: 480px) {
    padding-bottom: 76px;
  }
  @media only screen and (min-width: 640px) {
    width: 48%;
    margin-right: 33px;
  }
  @media only screen and (min-width: 1200px) {
    width: 52%;
    margin-right: 33px;
  }
	@extend %grey-dark;
	font-size: px2em(15);

  span.wpcf7-list-item {
    margin-left: 0;
    input {
      margin-right: 6px;
    }
  }

  .contact-form-header {
    font-size: px2em(26);
    line-height: 30px;
    font-family: 'vollkorn_regular', Georgia, serif;
    color: black;
    margin-top: 0;
    padding-bottom: 24px;
  }

	.paragraph-1{
    font-family: 'proxima_nova_regular', arial, sans-serif;
    font-size: px2em(15);
    line-height: 21px;
    padding-bottom: 18px;
	}

	.agree-checkbox{
	    margin-bottom: 27px;
	    font-family: 'proxima_nova_regular', arial, sans-serif;
	    @extend %grey-dark;
	    font-size: px2em(15);
	}

	.paragraph-3, .paragraph-4 {
	    margin-bottom: 27px;
	    font-family: 'proxima_nova_regular', arial, sans-serif;
	    @extend %grey-dark;
	    font-size: px2em(13);
      line-height: 19px;
	}
    }

    .right-section{
	width: 100%;
	float: none;
	display: inline-block;
	vertical-align: top;
  color: #3f3f3f;
  @media only screen and (min-width: 640px) {
    width: 40%;
    float: right;
  }
  @media only screen and (min-width: 1200px) {
    padding-left: 0;
    width: 45%;
  	float: right;
  }

	p{
    font-size: px2em(14);
    line-height: 24px;
    font-family: 'adelle_sans_regular';
	    input{
		@extend %form-element;
		max-width: 480px;
    width: 100%;
		margin: 6px 0;
    border-color: #d8d8d8 !important;
	    }

	    textarea{
		@extend %form-element;
		max-width: 480px;
    width: 100%;
		height: 250px;
		margin: 6px 0 0;
    resize: none;
    border-color: #d8d8d8 !important;
	    }

	    select{
		@extend %form-element;
		max-width: 480px;
    width: 100%;
		margin: 6px 0;
    appearance: none;
    border-radius: 0;
    border: 1px solid #d8d8d8;
    background: url('../images/nav-arrow-down.svg') no-repeat;
    background-position: calc(100% - 12px);
	    }

	    input[type="submit"]{
		@extend %blue-bg;
		font-family: 'adelle_sans_semibold';
		@extend %white;
		font-size: 14px;
		border-style: none;
		width: 88px;
		height: 36px;
		-moz-border-radius: 0px;
		-webkit-border-radius: 0px;
		border-radius: 0px;
    text-transform: uppercase;
    margin-bottom: 31px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 79px;
    }

    &:hover {
      background-color: #3f3f3f;
    }
	    }
	}
    }
    input[type="radio"] {
      height: 12px;
      width: 12px;
      border-color: #808080;
    }
}

//validation styles
.wpcf7-validation-errors {
  display: none !important;
}

.wpcf7-response-output {
  display: none !important;
}
.ajax-loader {
  display: none !important;
}
.wpcf7-not-valid-tip {
  display: none !important;
}
.wpcf7-not-valid {
  &::-webkit-input-placeholder {
    color: red;
  }
  &::-moz-placeholder {
    color: red;
  }
  &::-moz-placeholder {
    color: red;
  }
  &:-ms-input-placeholder {
    color: red;
  }
}
