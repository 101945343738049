// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Purpose: Implement styles to render the article grids on the article overview page and the category overview page

.article-grid{

  @media only screen and (min-width: 640px) {
    margin-left: -15px;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 46px;
  }

  .article-tile{
    display: inline-block;
    padding-left: 0;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 42px;
    float: none;
    clear: both;
    vertical-align: top;
    @media only screen and (min-width: 640px) {
      width: 49%;
      padding-right: 15px;
      padding-left: 15px;
    }
    @media only screen and (min-width: 1024px) {
      width: 33%;
      padding-right: 15px;
    }
    img{
      width: 100%;
      height: auto;
      margin-bottom: 26px;
    }

    .headline-date{
      display: inline-block;
      width: 100%;
      padding-left: 0;
      h6{
        font-family: 'vollkorn_regular', Georgia, serif;
        margin-top: 2px;
        font-size: px2em(22);
        line-height: 26px;
        a {
          font-family: 'vollkorn_regular', Georgia, serif;
          color: #3f3f3f;
          font-size: px2em(16);
          &:hover {
            color: #000;
          }
        }
      }

      .datecat{
        .date{
          font-family: 'proxima_nova_regular', arial, sans-serif;
          font-size: px2em(13);
          line-height: px2em(19);
          text-transform: uppercase;
        }
      }
    }

    .excerpt-link {
      width: 100%;
      padding-left: 0;
    }

    p{
      @extend %grey-dark;
      font-family: 'proxima_nova_regular', arial, sans-serif;
      font-size: px2em(15);
    }

    a{
      @extend %text-links-1;
      @extend %blue;
    }
  }
}
