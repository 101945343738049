.experts-in-area {
  margin-bottom: 94px;
  @media only screen and (max-width: 639px) {
    margin-bottom: 46px;
  }
  h3 {
    margin-top: 0 !important;
    margin-bottom: 19px !important;
    font-size: 20px;
    font-family: 'vollkorn_regular';
  }
  a {
    display: block;
    color: #1F93C3;
    line-height: 42px;

    &:hover {
      color: #3f3f3f;
    }
  }
}
