h1 {
    font-family: 'vollkorn_regular';
    font-size: px2em(42);
    line-height: 46px;
    text-transform: capitalize;
    @media only screen and (min-width: 768px) {
      font-size: px2em(48);
      line-height: 52px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: px2em(56);
      line-height: 60px;
    }
}

h2 {
    font-family: 'vollkorn_regular';
    font-size: px2em(45);
    line-height: 48px;

    a {
      text-transform: capitalize;
      font-family: 'vollkorn_regular';
      font-size: px2em(16);
      line-height: 48px;
      color: #3f3f3f;
      &:hover {
        color: #000;
      }
    }
}

h3 {
    font-family: 'vollkorn_regular';
    font-size: px2em(43);
    line-height: 46px;
    text-transform: capitalize;
    @extend %white;
}

h4 {
    font-family: 'vollkorn_regular';
    font-size: px2em(30);
    line-height: 30px;
    text-transform: none;
    //TODO: Text transform sentence case only (javascript)
    @extend %black;
}

h5 {
    font-family: 'vollkorn_regular';
    font-size: px2em(24);
    line-height: 30px;
    text-transform: capitalize;
    @extend %black;
}

h6 {
    font-family: 'vollkorn_regular';
    font-size: px2em(22);
    line-height: 28px;
    text-transform: capitalize;
    @extend %black;
}

body{
    font-family: 'proxima-nova-regular';
    font-size: 16px;
    line-height: 21px;
}

a, input {
    //TODO: Text transform sentence case only (javascript)
    font-family: 'adelle_sans_regular';
}

a:hover {
    //TODO: Text transform sentence case only (javascript)
    text-decoration: $link-hover-decoration;
}

a:focus, input:focus, select:focus {
  outline: 0;
}

html > body .container {
    padding-left: 24px;
    padding-right: 24px;
}

.sub-heading {
  font-size: px2em(20);
  line-height: 26px;
  color: #3f3f3f;
  font-family: 'vollkorn_regular', Georgia, serif;
}
