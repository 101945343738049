//Copyright 2016 Evoke Solutions
//
//Author: Alan Drees
//
//Implement styles needed for the lawyer grid page object

.lawyer-spacer {
  .no-left-padding {
    padding-right: 0;
    @media only screen and (min-width: 640px) {
      padding-right: 15px;
    }
    .vc_column-inner {
      padding-right: 0;
      @media only screen and (min-width: 640px) {
        padding-right: 15px;
      }
    }
  }
}

.lawyer-overview-spacer {
  .spacer {
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 48px;
    color: #f0f0f0;

    .inner-space{
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 1px;
    }
  }
}

.our-lawyers-grid {
  padding-bottom: 44px;
  padding-left: 16px;
  padding-right: 16px;
  @media only screen and (min-width: 480px) {
    padding-left: 0;
    padding-right: 0;
  }
  .vc_column-inner {
    padding-right: 0;
    @media only screen and (min-width: 640px) {
      padding-right: 15px;
    }
  }
}

.lawyer-section{
    margin-bottom: 44px;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0;
    @media only screen and (min-width: 622px) {
      min-height: 628px;
    }
    @media only screen and (min-width: 640px) {
      padding-right: 15px;
    }

    @media only screen and (min-width: 768px){
	text-align: left;
  min-height: 642px;
    }
    @media only screen and (min-width: 1200px) {
      min-height: 628px;
    }

    .image{
	width: 100%;
  max-width: 379px;
	overflow: hidden;
  margin: 0 auto 21px;
  @media only screen and (min-width: 768px) {
    margin: 0 0 21px;
  }
  &:hover {
    cursor: pointer;
  }
  img {
    width: 100%;
    max-width: 379px;
    height: auto;
  }
    }

    .title{
	font-family: 'proxima_nova_regular';
	@extend %black;
	font-size: px2em(14);
	margin-bottom: 8px;
	text-transform: uppercase;
  text-align: left;
  @media only screen and (max-width: 768px) {
    max-width: 312px;
    margin-left: auto;
    margin-right: auto;
  }
    }

    .namecreds{
	font-family: 'vollkorn_regular';
	font-size: px2em(22);
	margin-bottom: 19px;
  text-align: left;
  @media only screen and (max-width: 768px) {
    max-width: 312px;
    margin-left: auto;
    margin-right: auto;
  }
    }

    .blurb{
	font-family: 'proxima_nova_regular';
	@extend %grey-dark;
	font-size: px2em(15);
	margin-bottom: 25px;
	overflow: hidden;
  max-width: 312px;
  text-align: left;
  @media only screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
    }

    .full-bio{
	@extend %blue;
  text-align: left;
  @media only screen and (max-width: 768px) {
    max-width: 312px;
    margin-left: auto;
    margin-right: auto;
  }
	a{
	    @extend %blue;
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(16);
      text-decoration: none;
      &:hover {
        color: #3f3f3f;
      }
	}
  .navigation-arrow {
    position: relative;
    bottom: 1px;
  }
    }
}

.lawyer-no-left-padding{
    padding-left: 0px;
}
