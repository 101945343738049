.mackesy-map {
  .wpb_wrapper {
    width: 100%;
  }
  .vc_single_image-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    max-width: 100%;
    background-color: rgba(0,0,0,.8);
  }
  .wpb_single_image {
    opacity: .6;
    filter: alpha(opacity=60);
  }
  .wpb_text_column {
    content: '';
    height: 26px;
    width: 81px;
    background-image: url('../images/Homepage-map-logo-01.svg');
    background-size: cover;
    background-color: rgba(0,0,0,.4);
    position: absolute;
    left: 37%;
    top: 37%;
    @media only screen and (min-width: 460px) {
      left: 44%;
      top: 49%;
      height: 53px;
      width: 163px;
    }
  }
}
