// Copyright 2016 Evoke Solutions
//
// Author: Alan Drees
//
// Purpose: provides styles for the lawyer banner component (as seen on the homepage)
//

.vc_row-has-fill.lawyerbar2, .vc_row.lawyerbar2 {
  background-size: 302px !important;
  background-image: none !important;
  @media only screen and (min-width: 640px) {
    background-position: right calc(100% + 24px) !important;
  }
  @media only screen and (min-width: 1130px) {
    background-position: 82% calc(100% + 24px) !important;
  }
  .container {
    padding-left: 0;
  }
}

.lawyer-banner-bg{
    @extend %grey-bg;
    text-align: center;
}
.vc_inner.container {
  margin: 0 auto;
}

.lawyerbar2 {
  display: block !important;
  opacity: 1 !important;
  .vc_column-inner {
    padding-top: 0 !important;
  }
  .lawyer-banner {
    padding-top: 40px;
    padding-bottom: 36px;
    background-repeat: no-repeat;

    @media only screen and (max-width: 639px) {
      background-image: none !important;
    }
    @media only screen and (min-width: 640px) {
      background-position: bottom right;
      background-size: 33%;
    }
    @media only screen and (min-width: 790px) {
      background-position: bottom right;
      background-size: 33%;
    }
    @media only screen and (min-width: 900px) {
      background-position: right;
      background-size: 24%;
    }
    @media only screen and (min-width: 1200px) {
      background-position: calc(100% - 124px);
      background-size: 24%;
    }
  }
  .lawer-banner-controls {
    display: none;
  }
}

.lawyer-banner{
    // @extend %grey-bg;
    // display: none;
position: relative;
    .left-text{
	@extend %h3;
	color: #FFF;
  padding-right: 0;
  width: 100%;
	margin: auto 0px;
	display: inline-block;
	vertical-align: top;
  font-size: px2em(38);
  line-height: 42px;
  font-size: 'vollkorn_regular';
  padding-left: 24px;
  padding-bottom: 18px;
  @media only screen and (min-width: 480px) {
    font-size: px2em(43);
    line-height: 46px;
    padding-left: 15px;
  }
  @media only screen and (min-width: 767px) {
    width: 40%;
    padding-bottom: 0;
  }
  @media only screen and (min-width: 1200px) {
    width: 40%;
    padding-right: 109px;
    padding-bottom: 0;
  }
    }

    .banner-heading {
      color: white;
      max-width: 93%;
      padding-bottom: 34px;
    }

    .banner-blurb {
      font-family: 'proxima_nova_regular';
      font-size: px2em(15);
      line-height: 21px;
      padding-bottom: 10px;
    }

    .blue-button {
      a {
        display: inline-block;
        height: 36px;
        background-color: #1F93C3;
        line-height: 36px;
        width: 183px;
        text-align: center;
        margin-bottom: 96px;
        &:hover {
          background-color: #3f3f3f;
        }
      }
    }

    .right-text{
	@extend %white;
  padding-left: 24px;
	padding-right: 24px;
	width: auto;
	display: inline-block;
	vertical-align: middle;
  padding-top: 6px;
	.info{

	    margin-bottom: auto;
	    @media only screen and (min-width: 480px) {
        padding-left: 24px;
      }
      @media only screen and (min-width: 768px) {
        border-left-style: solid;
  	    border-left-width: 1px;
      }

	    .name-creds{
		font-family: 'vollkorn_regular', Georgia, serif;
		font-size: px2em(22);
		line-height: 26px;
		text-transform: capitalize;
		@extend %white;
    max-width: 200px;
	    }
      .credentials {
        font-family: 'vollkorn_regular', Georgia, serif;
    		font-size: px2em(20);
    		line-height: 24px;
    		text-transform: capitalize;
    		@extend %white;
        max-width: 200px;
        padding-bottom: 12px;
      }
	}

	.contact {
    font-size: px2em(13);
    font-family: 'proxima_nova_regular', arial, sans-serif;
	    span{
		display: block;
    color: white;
    &:hover {
      color: white;
    }
    a {
      color: white;
      &:hover {
        color: white;
      }
    }
	    }
	    @extend %white;
	}
    }

    .banner-image {
      @media only screen and (min-width: 768px) {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;
        div {
          height: 100%;
        }
        figure {
          position: absolute;
          bottom: 0;
        }
      }

      .wpb_single_image.wpb_content_element {
        margin-bottom: 0;
      }
      img {
        width: 100%;
        max-width: 450px;
      }
    }

    .lawyer-banner-image{
	height: 100%;
	overflow: hidden;
	z-index: 1;
	display: inline-block;
	vertical-align: top;

	img{
	    margin: auto 0;
	    z-index: 2;
	    position: absolute;
	}
    }

    a{
	@extend %white;
	height: 230px;
    }

    a:hover{
	@extend %white;
    }

    a:visited{
	@extend %white;
    }

    .lawyer-banner-data{
	display: none;
    }
}

.lawer-banner-controls{
    width: 100%;
    text-align: center;
    position: relative;
    top: 32px;

    .lawyer-banner-control{
	width: 16px;
	height: 16px;
	margin-right: 16px;
	background-color: #E6E6E6;
	display: inline-block;
	border-radius: 50%;
    }
}

.show{
    display: block;
}

.get-started-lawyer-banner {
  padding-top: 18px;
  padding-bottom: 0;
  @media only screen and (min-width: 640px) {
    padding-top: 66px;
    padding-bottom: 0;
  }
  h2 {
    font-family: 'vollkorn_regular', Georgia, serif;
    font-size: px2em(45);
    line-height: 48px;
    text-transform: capitalize;
    color: white;
    padding-bottom: 30px;
  }
  p {
    color: black;
    font-family: proxima_nova_regular;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 36px;
    line-height: 30px;
  }
  a {
    color: white;
    text-decoration: none;
    width: 183px;
    height: 36px;
    line-height: 36px;
    background-color: #1F93C3;
    text-align: center;
    display: block;
    margin-bottom: 96px;
    &:hover {
      background-color: #3f3f3f;
    }
  }

  .image-container {
    vertical-align: top;
    overflow: hidden;

    right: 0;
    bottom: -36px;
    @media only screen and (min-width: 767px) {
      position: absolute;
    }
    img {
      overflow: hidden;
      max-width: 450px;
      height: auto;
      width: 100%;
    }
  }
  .wpb_column {
    &.vc_column_container {
      display: none;
    }
  }
}
