//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//Purpose: Implements the styles needed to render a testimonial block

.testimonial {
    .left {
    	display: inline-block;
    	width: 5%;
    	height: 100%;
    	vertical-align: top;

    	.triangle-left {
    	    position: absolute;
    	    top: 50%;
    	    margin-top: -15px;
    	    width: 0;
    	    height: 0;
    	    border-style: solid;
    	    border-width: 29.5px 30px 29.5px 0;
    	    border-color: transparent #E7BC36 transparent transparent;
    	}

    }
    .content {
    	@extend %yellow-bg;
    	padding: 25px;
    	display: inline-block;
    	width: 100%;
      position: relative;
      &:after {
        top: 100%;
        left: 45%;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 30px solid #E7BC36;
        border-left: 30px solid transparent;
        border-bottom: 0;
        border-right: 30px solid transparent;
        @media only screen and (min-width: 768px) {
          top: 40%;
          left: 100%;
          content: '';
          border-left: 30px solid #E7BC36;
          border-top: 30px solid transparent;
          border-bottom: 30px solid transparent;
          border-right: 0;
        }
      }
    	.text {
    	    // @extend %callout-1;
          font-size: 1.5625em;
          font-weight: 1.5625em;
          color: map-get($colours, white);
    	    margin-bottom: 36px;
          font-family: vollkorn_regular;
          line-height: 30px;
    	}

    	.name{
    	    font-family: 'adelle_sans_regular';
    	    @extend %white;
    	    font-weight: bold;
    	    font-size: px2em(16);
    	}

    	.location {
    	    @extend %adelle-sans;
    	    @extend %white;
    	    margin-bottom: 18px;
          font-family: 'adelle_sans_regular';
    	}
    }
    .right  {
    	width: 5%;
    	display: inline-block;
    	height: 100%;

  	.triangle-right  {
  	    position: absolute;
  	    top: 50%;
  	    margin-top: -15px;
  	    width: 0;
  	    height: 0;
  	    border-style: solid;
  	    border-width: 30px 0 30px 30px;
  	    border-color: transparent transparent transparent #E7BC36;
	     }
    }

    .vc_column-inner {
      padding-right: 12px;
    }
}

.testimonial + .wpb_column.vc_column_container.vc_col-sm-8 {

  .vc_column-inner {
    padding-left: 12px;
  }
}
