// Search form
input::-webkit-input-placeholder {
  font-family: 'adelle_sans_regular';
  font-size: px2em(14);
}
input:-moz-placeholder {
  font-family: 'adelle_sans_regular';
  font-size: px2em(14);
}
input::-moz-placeholder {
  font-family: 'adelle_sans_regular';
  font-size: px2em(14);
}
input:-ms-input-placeholder {
  font-family: 'adelle_sans_regular';
  font-size: px2em(14);
}
input::-webkit-input-placeholder {
  font-family: 'adelle_sans_regular';
  font-size: px2em(14);
}
textarea:-moz-placeholder {
  font-family: 'adelle_sans_regular';
  font-size: px2em(14);
}
textarea::-moz-placeholder {
  font-family: 'adelle_sans_regular';
  font-size: px2em(14);
}
textarea:-ms-input-placeholder {
  font-family: 'adelle_sans_regular';
  font-size: px2em(14);
}
input, textarea {
  font-size: px2em(14);
  font-family: 'adelle_sans_regular';
}
input, textarea {
  border-color: #D8D8D8;
}
.search-form {
    @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
  color: #000
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
