.article-search {
    padding-bottom: 48px;
    position: relative;
    @media only screen and (min-width: 768px) {
  padding-bottom: 0;
    }
}

select{
    @extend %form-element;
    display: inline-block;
    width: 227px;
    border-width: 1px;
    border-style: solid;
    appearance: none;
    border-radius: 0px;
    margin-bottom: 6px;
}

input[type='search']{
    @extend %form-element;
    display: inline-block;
    width: 227px;
    background-repeat: no-repeat;
    background-position: 196px;
    margin-bottom: 0px;
}

input[type='submit'] {
  &.search-icon-submit {
    // @include svg-png-bkg('search-icon-mobile');
    width: 34px;
    height: 34px;
    background-position: center center;
    background-color: transparent;
    background-size: 16px 16px;
    border: 0;
    position: absolute;
    right: 32px;
    top: 1px;

    &:hover {
      // @include svg-png-bkg('search-icon');
      background-position: center center;
      background-color: transparent;
      border: 0;
      background-size: 16px 16px;
    }
  }
}
input::-webkit-input-placeholder {
    font-family: 'adelle_sans_regular';
    font-size: px2em(14);
}
input:-moz-placeholder {
    font-family: 'adelle_sans_regular';
    font-size: px2em(14);
}
input::-moz-placeholder {
    font-family: 'adelle_sans_regular';
    font-size: px2em(14);
}
input:-ms-input-placeholder {
    font-family: 'adelle_sans_regular';
    font-size: px2em(14);
}
