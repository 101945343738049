// Copyright 2016 Evoke Solutions
//
// Author: Alan Drees
//
// CSS needed to render the profile page for a lawyer

// .parent-pageid-166 {
//     width: 100%;

    .lawyer-writeup-info{
	@extend %grey-lighter-bg;
	position: relative;

  .vc_row-o-equal-height {
    min-height: 320px;
  }

  .vc_col-sm-7 {
    > div {
      height: 100%;
    }
  }

  .vc_single_image-wrapper {
    vertical-align: bottom;
    position: relative;
    @media only screen and (min-width: 1024px) {
      position: absolute;
      bottom: 0;
    }
    img {
      width: 100%;
      max-width: 450px;
      @media only screen and (min-width: 768px) {
        width: auto;
      }
    }
  }

	.writeup-lead{
	    font-family: 'vollkorn_regular';
	    @extend %grey-dark3;
	    font-size: px2em(20);
      line-height: 26px;
	    margin-top: 42px;
      margin-bottom: 43px;

	    @media only screen and (min-width: 768px){
		margin-top: 88px;
	    }
	}

	.writeup-content{
	    font-family: 'proxima_nova_regular';
	    @extend %grey-dark;
	    font-size: px2em(15);
      line-height: 21px;
	    padding-bottom: 8px;
      @media only screen and (min-width: 768px) {
        padding-bottom: 67px;
      }
	}

	.lawyer-image{

	    margin-bottom: 0px;
      vertical-align: bottom;

	    @media only screen and (min-width: 768px){
		text-align: left;
	    }
	}

    }

    .lawyer-profile-contact-form{
      margin-top: 42px;
      margin-bottom: 10px;
      @media only screen and (min-width: 480px) {
        margin-top: 90px;
        margin-bottom: 58px;
      }


	.contact-left{
	    vertical-align: top;
	    padding-left: 0px;

	    p.form-header{
		font-family: 'vollkorn_regular';
		font-size: px2em(26);
    line-height: 30px;
		margin-bottom: 18px;
    max-width: 450px;
    @media only screen and (min-width: 967px) {
      margin-bottom: 34px;
    }
	    }

	    p{
		@extend %grey-dark2;
		font-size: px2em(14);
		font-family: 'adelle_sans_regular';

		input{
		    height: 36px;
		    width: 100%;
		    margin-top: 12px;
		    margin-bottom: 0px;
		}
	    }

	    .input-label{
		font-size: px2em(14);
		margin-bottom: 24px;
	    }
	}

	.contact-right{
	    display: inline-block;

	    padding-left: 0px;

	    @media(min-width: 768px){
		padding-left: inherit;
	    }

	    p{
		@extend %grey-dark2;
		font-size: px2em(14);
		font-family: 'adelle_sans_regular';

		textarea{
		    width: 100%;
		    height: 225px;
		    margin-top: 12px;
		    margin-bottom: 0px;

		}
	    }

	}

	input, textarea{
	    @extend %form-element;
	}

	.submit-button{
	    padding-left: 0px;

	    @media(min-width: 768px){
		padding-left: inherit;
	    }

	    input{
		@extend %white;
		@extend %blue-bg;
		font-family: 'adelle_sans_regular';
		text-transform: uppercase;
		width: 88px;
		height: 36px;
		font-size: px2em(14);
	    }
	}

	.submit-button {
	    input[type="submit"] {
        &:hover {
          background-color: #3f3f3f;
        }
      }
	}
    }
// }

.main{ width: 100% !important;}
