//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//Purpose: Implement CSS rules defined for the practice area grid


.practice-section{
    margin-bottom: 44px;
    min-height: 448px;
    text-align: left;
    @media only screen and (min-width: 768px) {
      height: 484px;
    }

    .image{
      max-height: 201px;
	overflow: hidden;
	@extend %grey-lightest-bg;
	margin-bottom: 43px;
  img {
    width: 100%;
    display: block;
    height: 100%;
    position: relative;
    bottom: 36px;
  }
    }

    .title{
	font-family: 'vollkorn_regular', Georgia, serif;
	@extend %black;
	font-size: px2em(22);
  line-height: 26px;
	margin-bottom: 8px;
  a {
    font-family: 'vollkorn_regular', Georgia, serif;
  	color: #3f3f3f;
    &:hover {
      color: #000;
    }
  }
    }

    .blurb{
	font-family: 'proxima_nova_regular', arial, sans-serif;
	@extend %grey-dark;
	font-size: px2em(15);
	margin-bottom: 25px;
	border-style: solid 1px black;

    }

    .area-link{
	@extend %blue;
	text-decoration: underline;
	a{
	    @extend %blue;
	    @extend %adelle-sans;
	    font-size: px2em(16);
	}
    }

    .text-link {
      @extend %blue;
	    @extend %adelle-sans;
	    font-size: px2em(16);
      text-transform: capitalize;
      &:hover {
        color: #3f3f3f;
      }
    }
}

.practices-grid {
  .vc_column-inner {
    padding: 0;
  }
}
