.lawyer-overview-top {
  margin-top: 22px;
  margin-left: 0px;

  h1{
  font-family: 'vollkorn_regular';
  @extend %black;
  margin-top: 0px;
  font-size: px2em(50);
  line-height: 54px;
  @media only screen and (min-width: 480px) {
  font-size: px2em(56);
  line-height: 60px;
  max-width: 440px;
  }
  }

  .heading-text-block{
  font-family: 'vollkorn_regular';
  @extend %grey-dark2;
  font-size: px2em(20);
  line-height: 26px;
  margin-bottom: 0;
  padding-top: 6px;
  }

  .blurb-text-block{
  font-family: 'proxima_nova_regular';
  @extend %grey-dark;
  font-size: px2em(15);
  padding-top: 18px;
  a {
    font-family: 'proxima_nova_regular';
    color: grey;
    &:hover {
      color: grey;
      cursor: default;
    }
  }
  }

  .category-select-container {
    position: relative;
    float: left;
    clear: both;
    padding-top: 0;
    border: 0;
    border-width: 0;
    margin-top: 24px;
    margin-bottom: 24px;
    z-index: 2;

    ul {
      margin: 0;
      height: 36px;
    }

    &:nth-of-type(2) {
      margin-top: 0;
      margin-bottom: 48px;
      z-index: 1;
    }

    @media only screen and (min-width: 1200px) {
      margin-top: 96px;
      margin-bottom: 50px;
      clear: none;

      &:nth-of-type(2) {
        margin-top: 96px;
        margin-left: 24px;
      }
    }
  }

  .spacer{
  width: 100%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 48px;
  color: #f0f0f0;

  .inner-space{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 1px;
    border-bottom: 1px;
    border-style: solid;

  }
  }
}

.lawyer-overview-spacer {
  width: auto;

  border-top: 1px solid #f0f0f0;
  @media only screen and (min-width: 480px) {
    width: 100%;
  }
}

.our-lawyers, .personal-injury-lawyers, .licensed-law-clerks, .other-areas-of-law-lawyers, .licensed-paralegal {
  .spacer .inner-space {
    border-bottom: 0 !important;
  }
  .vc_col-sm-6 {
    .vc_column-inner {
      @media only screen and (max-width: 479px) {
        padding-left: 0;
      }
    }
  }
}
