//Footer css definitions

.footer{

  @extend %grey-lighter-bg;
  padding-top: 10px;
    a{
      color: #1F93BC;
    	font-size: 13px;
    	line-height: 36px;
    }

    .footer-links{
	width: 100%;

	.quick-links{
    margin-bottom: 36px;
	    h3{
    		margin-bottom: 36px;
        font-family: 'vollkorn_regular';
        font-size: 20px;
        color: #000;
	    }



      @media only screen and (min-width: 616px) {
        display: inline-block;
        width: 100%;
        margin-bottom: 14px;
      }

      @media only screen and (min-width: 1025px) {
        float: left;
        width: initial;
        margin-right: 24px;
        margin-bottom: 48px;
      }
	}

	.recent-posts {

	    h3{
    		margin-bottom: 36px;
        font-family: vollkorn_regular;
        font-size: 20px;
	    }

	    margin-bottom: 48px;

      @media (min-width: 616px) {
        display: inline-block;
      }

      @media (min-width: 1025px) {
        float: left;
        width: initial;
      }
	}

	.left-content {

    @media (min-width: 616px) {
      width: 227px;
      float: left;
      margin-right: 26px;
      border-bottom: 1px dotted #929497;
    }
	}

	.right-content {
      border-bottom: 1px dotted map-get($colours, grey3);

      @media (min-width: 616px) {
        width: 227px;
        float: left;
      }
	}

	.qlink {
	    font-size: 13px;
	    height: 37px;
	    border-style: dotted;
	    border-top-width: 1px;
	    text-align: left;
	    overflow: hidden;
      border-top-color: map-get($colours, grey3);

      a:hover, a:active, a:focus {
        color: map-get($colours, grey-dark3);
        cursor: pointer;
      }
	}

	.border-bottom {
	    // border-bottom-width: 1px;
	}
    }
}

.bottom-banner{
	.logo-holder{
	    display: inline-block;
	    vertical-align: middle;
      margin-bottom: 10px;
      float: left;
      margin-top: 32px;

	    .logo{
    		margin-right: 107px;
    		@extend %logo-footer;
	    }
	}

	.address {
	    span {
		      display: inline;
	    }

	    @extend %white;
	    font-family: get_map($fontContext, copyright-footer);
	    font-size: pxtoem(13);
	    display: inline-block;
	    margin-right: 48px;
      font-family: proxima_nova_regular;
      margin-top: 23px;
      font-size: 12px;
	}

	.contact{
	    span{
		      display: inline;
          color: white;
          &:hover {
            color: white;
          }
          a {
            color: white;
            &:hover {
              color: white;
            }
          }
	    }

	    @extend %white;
	    font-family: get_map($fontContext, copyright-footer);
	    font-size: pxtoem(13);
	    display: inline-block;
      font-family: proxima_nova_regular;
      margin-bottom: 20px;
      margin-top: 20px;
      font-size: 12px;
	}
}

.content-info{
    width: 100%;

    .bottom-banner{
    	// @include bp(md){height: 98px;}
    	@extend %grey2-bg;
    }

    .copyright{
    	@extend %footer-copyright-line;
    	margin-top: 24px;
    	margin-bottom: 24px;
      position: relative;
    }
}

.footer-copyright {
  font-family: proxima_nova_regular;
  font-size: 11px;
  position: relative;
}

.copyright-text-section {
  line-height: 1.7;
  color: #808080;
  a {
    color: #808080;
    &:hover {
      color: #808080;
      text-decoration: underline;
    }
  }
  @media only screen and (min-width: 400px) {
    float: left;
  }
}

.copy-right-text-half {

  @media only screen and (min-width: 400px) {
    width: 100%;
    display: inline-block;
  }

  @media only screen and (min-width: 767px) {
    width: initial;
    display: block;
  }
}

.privacy-link {
    font-family: 'proxima_nova_regular' !important;
    font-size: 11px !important;
    line-height: 13px !important;
    position: relative;
    text-decoration: none;
    color: #808080 !important;
}

.privacy-link:visited {
  color: #808080 !important;
}

.privacy-link:hover, .privacy-link:active {
    text-decoration: none;
    color: rgb(64, 64, 64) !important;
}

.bbb-footer-image a{
  width: 69px;
  height: 26px;
  background-image: url('../images/bbb-logo.svg');
  background-repeat: no-repeat;
  float: left;
  margin: 20px 0 22px;

  @media only screen and (min-width: 400px) {
    margin: 0;
    position: absolute;
    top: -1px;
    right: 20px;
  }
}

.beadonor-footer-image a{
  width: 100%;
  height: 26px;
  background-image: url('../images/beadonor_logo.svg');
  background-repeat: no-repeat;
  background-position: 50% left;
  float: left;

  @media only screen and (min-width: 400px) {
    width: 100px;
    float: right;
    background-position: 50%;
    position: relative;
    left: 4px;
  }

  @media only screen and (min-width: 768px) {
    background-position: right;
    display: block;
    margin-top: 24px;
  }

  @media only screen and (min-width: 922px) {
    left: auto;
    margin-top: 0;
    position: absolute;
    top: -1px;
    right: 134px;
  }
}
