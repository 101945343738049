.vc_images_carousel {
  width: 100% !important;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  .vc_carousel-indicators {
    li {
      background: rgba(0,0,0,.3);
      border: 0 !important;
      &.vc_active {
        background: white !important;
      }
    }
  }
}
