/* Copyright 2016 Evoke Solutions
 *
 * Author: Alex Lepsa
 *
 * Purpose: Soft Transitions
 *
 * Usage: Include on regular element: @include transition-soft();
 *        Include on ':hover' : @include transition-hard();
 */

$transition-speed: 0.25s;
$transition-soft: opacity $transition-speed ease, background-color $transition-speed ease, color $transition-speed ease, border-color $transition-speed ease;
$transition-hard: opacity 0s, background-color 0s, color 0s, border-color 0s;

@mixin transition-soft($transition: $transition-soft) {
 -webkit-transition:  $transition;
 -moz-transition:     $transition;
 -ms-transition:      $transition;
 -o-transition:       $transition;
  transition:         $transition;
}

@mixin transition-hard($transition: $transition-hard) {
  -webkit-transition:  $transition;
  -moz-transition:     $transition;
  -ms-transition:      $transition;
  -o-transition:       $transition;
   transition:         $transition;
}

// Usage: @extend %soft-transition;
%soft-transition {
  @include transition-soft();

  &:hover {
    @include transition-hard();
  }
}
