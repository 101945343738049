//Copyright 2016 Evoke Solutions
//Author: Alan Dres
//Implements the styles needed for the article overview page
.articles-resources{

    .category-select-container {
	padding-top: 0;
	border: 0;
	position: relative;
	bottom: 2px;
  margin-bottom: 0;
  height: 60px;
  @media only screen and (min-width: 480px) {
    margin-bottom: 48px;
    height: 84px;
  }
    }

    .featured-article{
	padding-left: 0;
  @media only screen and (max-width: 479px) {
    padding-right: 0;
  }

	img{
	    width: 100%;
      height: auto;
	    margin-bottom: 48px;
      padding-top: 32px;
	}

	.headline-date{
	    float: left;
      padding-left: 0;

	    h2{
		margin-top: 0px;
		padding-bottom: 36px;
		padding-left: 0;
	    }
	    .datecat{
		font-family: 'proxima_nova_regular', arial, sans-serif;
		font-size: px2em(13);
		line-height: px2em(19);

    a {
      font-family: 'proxima_nova_regular', arial, sans-serif;
      color: #3f3f3f;
      &:hover {
        color: #000;
      }
    }


		.date{
		    text-transform: uppercase;
		}
		.category{
		    text-transform: uppercase;
		}
	    }
	}

	.excerpt-link{
	    float:right;
	    padding-top: 4px;
	    padding-left: 0;
	    p{
		@extend %grey-dark;
		font-family: 'proxima_nova_regular', arial, sans-serif;
		font-size: px2em(15);
	    }

	    a{
		@extend %text-links-1;
		@extend %blue;
	    }
	}
    }


    .filters{
	margin-top: 88px;

	.vc_col-sm-4 {
	    padding-left: 0;
	}

	.article-search {
	    padding-bottom: 48px;
      position: relative;
	    @media only screen and (min-width: 768px) {
		padding-bottom: 0;
	    }
	}

	select{
	    @extend %form-element;
	    display: inline-block;
	    width: 227px;
	    border-width: 1px;
	    border-style: solid;
	    appearance: none;
	    border-radius: 0px;
	    margin-bottom: 6px;
	}

	input[type='search']{
	    @extend %form-element;
	    display: inline-block;
	    width: 227px;
	    background-repeat: no-repeat;
	    background-position: 196px;
	    margin-bottom: 0px;
	}
  input[type='submit'] {
    @include svg-png-bkg('search-icon-mobile');
    width: 34px;
    height: 34px;
    background-position: center center;
    background-color: white;
    background-size: 16px 16px;
    border: 0;
    position: absolute;
    top: 1px;
    left: 192px;
    &:hover {
      @include svg-png-bkg('search-icon');
      background-position: center center;
      background-color: white;
      border: 0;
      background-size: 16px 16px;
    }
  }
	input::-webkit-input-placeholder {
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(14);
	}
	input:-moz-placeholder {
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(14);
	}
	input::-moz-placeholder {
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(14);
	}
	input:-ms-input-placeholder {
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(14);
	}

    }

    .paging-buttons {
	border-top: 1px solid #d9d9d9;
	padding-top: 48px;
  padding-bottom: 95px;
    }
}
