// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Purpose: Implement styles for the visual composer sidebar used in the post templates
.post-sidebar{
  padding-top: 48px;
  width: 100%;
  display: inline-block;
  @media only screen and (min-width: 480px) {
    width: 48%;
  }
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
  .search-form{
    padding-bottom: 24px;
    width: 227px;
    position: relative;
    .search-submit {
      width: 34px;
      height: 32px;
      bottom: 25px;
      left: 192px;
      @include svg-png-bkg('search-icon-mobile');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: 50%;
      position: absolute;
      border: 0;
      background-color: white;
      &:hover {
        background-color: white;
        @include svg-png-bkg('search-icon');
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }

    input[type='search'] {
      @extend %form-element;
      width: 227px;
      background-repeat: no-repeat;
      background-position: 196px;
      margin-bottom: 0px;
      box-shadow: none;
    }
    input::-webkit-input-placeholder {
      font-family: 'adelle_sans_regular';
      font-size: px2em(14);
    }
    input:-moz-placeholder {
      font-family: 'adelle_sans_regular';
      font-size: px2em(14);
    }
    input::-moz-placeholder {
      font-family: 'adelle_sans_regular';
      font-size: px2em(14);
    }
    input:-ms-input-placeholder {
      font-family: 'adelle_sans_regular';
      font-size: px2em(14);
    }
  }

  .category-select-container {
    padding-top: 24px !important;
    border-color: #929493;

    &:after {
      top: 25px;
    }
  }

  .recent-articles-container{

    span{
      @extend %black;
      @extend %paragraph-2;
      margin-bottom: 48px;
    }


    .article-sidebar-list{
      margin-top: 38px;

      li{
        border-color: #929493;
        width: 227px;
        border-top-style: dotted;
        border-top-width: 1px;
        overflow: hidden;
        height: 36px;
        line-height: 36px;
        &:last-of-type {
          border-color: #929493;
          border-bottom-width: 1px;
          border-bottom-style: dotted;
        }

        a{
          @extend %text-links-1;
          @extend %blue;
          line-height: 36px;
          font-size: px2em(13);
          &:hover {
            color: #3f3f3f;
          }
        }
      }
    }
  }
  &.articles-sidebar {
    padding-top: 0;
  }
}
