//nav
//Alan Drees

.nav-primary {
  // display: none;


  > ul {

    > li {
      display: block;
    }
  }
}

@media (max-width: 1024px) {
  .nav-primary {
    > ul > li {
      > a {
        display: block;
        height: 32px;
        padding-left: 25px;
        color: map-get($colours, grey-dark3);
      }
    }
    > ul > li:not(.mobile-yellow-link) > a,
    .menu-call-today-1-905-525-2341,
    .menu-tell-us-about-your-case {
      text-transform: capitalize;
      @media only screen and (min-width: 1024px) {
        text-transform: uppercase;
      }
    }

    > ul > li.mobile-yellow-link > a {
      color: map-get($colours, white);
    }
  }

  .sub-menu {
    background: map-get($colours, grey-lighter);

    > li {

    }
  }

  .single-level-ul, .multi-level-ul {
    background: map-get($colours, white);
    padding-top: 16px;
    position: relative;

    > ul > li {

      //sub-menu
      ul > li > a{
        display: block;
        padding: 14px 0 14px 24px;
        color: map-get($colours, blue);
      }
    }

    > a {
      background-repeat: no-repeat;
      background-size: 12px 15px;
      background-position: right 25px top 4px;
    }
  }

  .single-level-ul .personal-li .menu-item .menu-item-has-children .menu-personal-injury-law .arrow-down > ul {
    width: 100%;
  }

  .hide-menu-header > a, .menu-header > a {
    display: none;
  }

  li.menu-item.menu-get-started {
    background: map-get($colours, white);
    padding: 20px 0 0 0;
  }

  .mobile-menu-header-spacer {
    height: 20px;
  }

  .mobile-menu-header-spacer2 {
    height: 38px;
  }

  .mobile-yellow-link {
    background: map-get($colours, yellow);

    > a {
      padding-top: 16px;
      padding-bottom: 32px;
      background-position: right 25px top 14px;
    }
  }

  .menu-call-today-1-905-525-2341 {
    > a {
      background-image: url('../images/phone-white.svg');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: right 25px top 14px;
    }
  }

  .menu-tell-us-about-your-case {
    > a {
      background-image: url('../images/speech-bubble-white.svg');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: right 25px top 14px;
    }
  }

  .sub-menu-header.menu-personal-injury-law,
  .sub-menu-header.menu-other-areas-of-law,
  .sub-menu-header.menu-all-our-lawyers,
  .sub-menu-header.menu-articles-resources-overview {
    > a {
      padding: 14px 0 0 24px;
      position: relative;
      top: 0;
      color: rgb(83, 83, 83);
      display: block;
      height: 48px;
      display: block;
    }
  }

  .mobile-click-area {
    display: block;
  }
}

@media (min-width: 1025px) {

  .nav-primary {
    list-style: none;
    vertical-align: middle;
    // height: 500px;
    position: absolute;
    z-index: 99;
    margin-left: 8px;

    //top level menu
    > ul  {
      > li:not(:last-child) {
        margin-right: 48px;
      }
      > li {
        display: inline-block;
        position: relative;
        margin-bottom: 0px;
        //sub section menu
        > ul {
          //@extend %grey-lighter-bg;
          background-color: map-get($colours, white);
          position: absolute;
          // left: -9999px;
          display: none;
          white-space: nowrap;
          list-style-type: none;
          left: -24px;
          top: 58px;
          border: 1px solid map-get($colours, grey-light);

          > li  {
            padding-top: 1px;
            float: left;

            //navigation location menu
            > ul{
              //@extend %grey-lighter-bg;
              background-color: map-get($colours, white);
              white-space: nowrap;
              width: 100%;
              padding-right: 24px;
              > li{
                //@extend %grey-lighter-bg;
                background-color: map-get($colours, white);
                border-width: 0px;
                border-bottom-width: 1px;
                border-style: dotted;
                border-color: #929493;
                // @extend %grey3-bc;
                color: get_map($colours, grey3-bc);
                width: 227px;
                height: 36px;
                >a{
                  //@extend %grey-lighter-bg;
                  background-color: map-get($colours, white);
                  color: map-get($colours, blue);
                  z-index: 100000;
                  font-size: px2em(13);
                  height: 100%;
                  width: 100%;
                  position: relative;
                  top: 6px;
                  cursor: pointer;
                }

                a:hover, a:active, a:focus {
                  color: map-get($colours, grey-dark3);
                  cursor: pointer;
                  text-decoration: none;
                }
              }


            }

            a{
              border-width: 0px;
              // @extend %nav-items-1;

              font-family: adelle_sans_regular;
              font-size: px2em(15);
              line-height: px2em(24);
              letter-spacing: 0.016em;

              //@extend %grey-lighter-bg;
              background-color: map-get($colours, white);
              color: get_map($colours, grey-lighter-bg);


            }


          }


          > .menu-item-has-children{

            ul {
            }

            > a {
              // @extend %grey-dark3;
              font-family: adelle_sans_regular;
              color: map-get($colours, grey-dark3);
              font-size: px2em(14);
              margin-bottom: 36px;
            }
          }

          > a{
            border-width: 0px;
            // @extend %nav-items-1;

            font-family: adelle_sans_regular;
            font-size: px2em(15);
            line-height: px2em(24);
            letter-spacing: 0.016em;

            //@extend %grey-lighter-bg;
            background-color: map-get($colours, white);
            color: get_map($colours, blue);
          }
        }

        > a{
          display: inline-block;
          vertical-align: middle;
          color: map-get($colours, grey-dark3);
          height: 59px;
          line-height: 59px;
        }
      }

      >li:not(.menu-get-started):after {
        background-image: url('../images/nav-arrow-down.svg');
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 12px;
        content:"";
        background-position: 10px 6px;
      }

      >li:not(.menu-get-started):hover:after {
        background-image: url('../images/nav-arrow-up.svg');
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 12px;
        content:"";
        background-position: 10px 6px;
      }

      > li:hover{

        ul{
          // left: 0;
          display: block;


          a{
            text-decoration: none;
          }

          li{
            a:hover{
            }

            ul{
            }

          }
        }

        > a{
        }
      }
    }


  }

  .sub-menu-header, {
    > a {
      position: relative;
      top: -14px;
    }
  }

  .hide-menu-header > a {
    position: relative;
    z-index: -99;
  }

  .menu-item.menu-item-has-children.practice-areas-menu,
  .menu-item.menu-item-has-children.menu-articles-resources {
    > ul {
      width: 779px;
    }
  }

  .menu-item.menu-item-has-children.menu-our-lawyers {
    > ul {
      width: 529px;

      > li:nth-child(2) {
        clear: left;

        > a {
          display: none;
        }
      }

      > li:last-child {
        position: absolute;
        top: 24px;
        right: 0;
      }
    }
  }

  .menu-item.menu-item-has-children.menu-articles-resources {
    > ul {
      width: 279px;

      > li:not(:first-child) > a {
        display: none;
      }
    }
  }

  .menu-item.menu-item-has-children.menu-articles-resources > ul > li:nth-child(2) {
    top: 82px;
  }

  .multi-level-ul > ul {
    padding: 24px 0 42px 24px;
  }

  .single-level-ul > ul {
    padding: 22px 0 42px 24px;
  }

  a {
    cursor: pointer;
  }

  #menu-primary_navigation > li:hover {
    border-bottom: 4px solid map-get($colours, yellow);
    color: map-get($colours, grey-dark3);
    height: 58px;
  }

  .mobile-menu-header-spacer, .mobile-menu-header-spacer2 {
    display: none;
  }

  .nav-primary>ul>li.mobile-yellow-link {
    display: none;
    a {
      font-size: px2em(16);
    }
  }

  .nav-primary > ul .single-level-ul, .nav-primary > ul .multi-level-ul {
    > a {
      background: none;
    }
  }
  .practice-areas-overview {
    display: none;
  }

  .our-lawyers-top-level > ul > li:nth-child(2) {
    margin-top: 1px;
  }

  .multi-level-ul > ul > li.menu-personal-injury-law,
  .multi-level-ul > ul > li.menu-other-areas-of-law {
    a:hover {
      text-decoration: none;
    }
  }

  .practice-areas-menu > ul {
    padding: 64px 0px 42px 24px;
  }
}

@media (min-width: 1201px) {

  .menu-item.menu-item-has-children.menu-our-lawyers {
    > ul {
      width: 779px;

      > li:nth-child(2) {
        clear: none;

        > a {
          display: block;
          margin-bottom: 0;
        }
      }
    }
  }

  .menu-item.menu-item-has-children.menu-articles-resources {
    > ul {
      width: 529px;

      > li:nth-child(2) {
        clear: left;


        > a {
          display: none;
        }
      }

      > li:last-child {
        position: absolute;
        top: 46px;
        right: 0;
      }
    }
  }

  .mobile-click-area {
    display: none;
  }
}

.arrow-up > a {
  background-image: url('../images/nav-arrow-up.svg');
}

.arrow-down > a {
  background-image: url('../images/nav-arrow-down.svg');
}

.mobile-click-area {
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0px;
}

.multi-level-ul > ul > li ul > li.other-lawyers-link {

  > a {
    color: map-get($colours, grey-dark3);
  }

  a:hover {
    color: #000;
    text-decoration: none;
  }

  a:active, a:focus {
    color: map-get($colours, grey-dark3);
  }
}

li.sub-menu-header.menu-item.menu-item-has-children.menu-all-our-lawyers,
li.sub-menu-header.menu-item.menu-item-has-children.menu-articles-resources-overview {
  > a {
    color: map-get($colours, blue);
  }

  a:hover, a:active, a:focus {
    color: map-get($colours, grey-dark3);
    cursor: pointer;
    text-decoration: none;
  }
}
