.inner-class-link {
  @extend %soft-transition;
  @include transition-soft();
  a {
    font-family: 'adelle_sans_semibold';
    font-size: px2em(14);
  }
  &:hover {
    @include transition-hard();
  }
}
