.banner {
    @extend %white-bg;
    //@extend %grey-light-bbc;
    height: 60px;
    border-bottom: 1px solid map-get($colours, grey-lightest2);

    .header-bottom {

      @media (max-width: 1024px) {
        width: 100%;

        > div {
          width: 100%;
          padding: 0;
          position: absolute;
          z-index: 99;
        }
      }

      @media (min-width: 1025px) {

        > div {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    .header-top {
	     height: 60px;
       border-bottom: 1px solid map-get($colours, grey-lightest2);

       @media (min-width: 1025px) {
         height: 139px;
       }

	// Logo
	.logo {
      @extend %logo-header;
      color: black;

      @media (min-width: 1025px) {

          margin: 46px 0 0 0;
          background-size: auto;
          width: 206px;
          height: 56px;
      }
	}

	.right-of-logo{
	    margin-top: 24px;
      text-align: right;
      display: none;

  .secondary-navigation {
    display: inline-block;

    li {
      display: inline-block;
    }
  }

  .header-faq-contact-search {
		      margin-top: 34px;
          margin-right: 16px;

		.header-contact-us a {
		    margin-right: 48px;
		    @extend %nav-items-1;
		    @extend %yellow;
        font-size: px2em(14);
        font-family: 'adelle_sans_regular', arial, sans-serif;
        &:hover {
          color: #535353;
        }
		}

		.header-faq a {
		    margin-right: 18px;
		    padding-left: 33px;
        font-size: px2em(14);
        font-family: 'adelle_sans_regular', arial, sans-serif;
		    @extend %nav-items-1;
		    @extend %yellow;

		    // @import bp(xs){
			  //      display: none;
		    // }
        &:hover {
          color: #535353;
        }
		}

		input {
		    @extend %form-element;
		    width: 312px;
        font-family: 'adelle_sans_regular';
        line-height: 36px;
		}
    .search-icon-submit {
      width: 34px;
      height: 34px;
      position: absolute;
      // @include svg-png-bkg('search-icon');
      // background-size: 16px 16px;
      // background-position: center center;
      border: 0;
      bottom: 1px;
      right: 1px;
      left: auto;
      &:hover {
        // @include svg-png-bkg('search-icon-mobile');
        // background-size: 16px 16px;
        // background-position: center center;
        border: 0;
        bottom: 1px;
        right: 1px;

        left: auto;
      }
    }
	}

	    .header-confidential a,.header-calltoday a{
		      @extend %nav-items-2;
		        @extend %black;
	    }

	    img.header-tell-us, img.header-call-img{
		margin-right: 8px;
    position: relative;
    bottom: 2px;
	    }

      @media (min-width: 1025px) {
          display: block;
      }

	}
    }

    .site-search {
      position: relative;
	display: inline-block;
    }

    .header-bottom {

    }

    @media (min-width: 1025px) {
        height: 197px;
    }
}

.top-contact-info {
  margin-right: 16px;

  > div:first-child {
    margin-left: 24px;
  }
}

.header-confidential, .header-faq-contact-search, .header-calltoday, .right-of-logo {
  padding: 0;
  float: right;
}

.header-calltoday, .header-confidential {
  > a {
    font-size: 12px;
    color: #3f3f3f !important;
    &:hover {
      color: #000 !important;
    }
  }
}

.right-of-logo-mobile {
  float: right;
  margin-top: 22px;
  display: block;

  > div {
    float: right;
  }

  > div:first-child > a {
    font-size: 17px;
    color: map-get($colours, grey-dark3);
    position: relative;
    top: -3px;
  }

  @media (min-width: 1025px) {
      display: none;
  }
}

.get-started-header {
  display: none;
  float: right;

   > a {
    font-size: 13px;
    color: map-get($colours, grey-dark3);
    float: right;
    &:hover {
      color: #000;
    }
  }

  @media (min-width: 481px) {
      display: block;
  }

  @media (min-width: 1025px) {
      display: none;
  }
}

#mobile-search-container {
    margin: 2px 35px 0 48px;
    cursor: pointer;
}

#mobile-menu-container {
  margin: 2px 0 0 0;
  cursor: pointer;
}

.mobile-search-icon {
  width: 17px;
  height: 17px;
  background-image: url('../images/search-icon-mobile.svg');
  background-repeat: no-repeat;
  background-size: 17px;
}

.mobile-show-menu-icon {
  width: 17px;
  height: 17px;
  background-image: url('../images/mobile-menu-show.svg');
  background-repeat: no-repeat;
  background-size: 17px;
}

.mobile-closing-x {
  width: 17px;
  height: 17px;
  background-image: url('../images/mobile-menu-hide.svg');
  background-repeat: no-repeat;
  background-size: 17px;
}

#mobile-search-bar-container {
  display: none;
}

.banner .header-top .right-of-logo .header-faq-contact-search input {
    padding-right: 26px;
}

.mobile-search-bar > input[type="search"] {
  width: 100% !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 1px solid #f0f0f0 !important;
  border-left: none !important;
  height: 48px;
  padding: 20px 50px 20px 25px;
  position: relative;
  z-index: 9998;
  font-family: 'adelle_sans_regular';
  @include svg-png-bkg('search-icon');
  background-size: 16px 16px !important;
  background-position: calc(100% - 24px) !important;
  &:hover {
    @include svg-png-bkg('search-icon-mobile');
  }
}

.site-search {
  .search-form {
    @include svg-png-bkg('search-icon');
    background-size: 16px 16px;
    background-position: calc(100% - 12px) !important;
    &:hover {
      @include svg-png-bkg('search-icon-mobile');
    }
  }
  .search-icon-submit {
    background-color: transparent !important;
  }
}

.mobile-search-bar {
  .search-icon-submit {
    display: none;
    background-color: transparent;
    z-index: 9999;
    width: 48px;
    height: 46px;
    position: absolute;
    content: '';
    border: 0;
    top: 66px !important;
    right: 14px !important;
    display: block;
    left: auto;

  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .container {
      width: 100%;
  }
}
