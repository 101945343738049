// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Purpose: Implement some site-wide content styles

%nav-items-1 {
    font-family: adelle_sans_regular;
    font-size: px2em(15);
    line-height: px2em(24);
    letter-spacing: 0.016em;
    //color: yellow, white, grey-dark;
}

%nav-items-2 {
    font-family: adelle_sans_regular;
    font-size: px2em(14);
    line-height: px2em(24);
    letter-spacing: 0.016em;
    //color: black, grey-dark;
}

%smallbody {
    font-family: 'proxima_nova_regular', arial, sans-serif;
    font-size: px2em(13);
    line-height: px2em(19);

}

%callout-1{
    font-family: 'vollkorn_regular';
    font-size: px2em(25);
    line-height: px2em(30);
    //TODO: Text transform sentence case only (javascript)
    @extend %white;
}

%callout-2{
    font-family: adelle_sans_semibold;
    font-size: px2em(19);
    line-height: px2em(24);
    text-transform: capitalize;
    @extend %white;
}

%paragraph-1{
    font-family: 'vollkorn_regular';
    font-size:   px2em(26);
    line-height: px2em(30);
    @extend %black;
}

%paragraph-2{

    font-size: px2em(20);
    line-height: px2em(26);
}

%paragraph-3{
    font-family: 'proxima_nova_regular', arial, sans-serif;
    font-size: px2em(14);
    line-height: px2em(30);
    text-transform: uppercase;
    @extend %black;
}

%paragraph-4{
    font-family: vollkorn_regular;
    font-size: px2em(20);
    line-height: px2em(30);
}

%text-links-1{
    font-family: adelle_sans_regular;
    font-size: px2em(16);
    line-height: px2em(24);
}

%text-links-2{
    font-family: adelle_sans_regular;
    font-size: px2em(13);
    line-height: px2em(30);
    @extend %blue;
}

%caption-1 {
    font-family: adelle_sans_regular;
    //TODO: Text transform sentence case only (javascript)
    font-size: px2em(16);
    line-height: px2em(30);
    //color: black, grey-dark;
}

%caption-2 {
    font-family: adelle_sans_regular;
    //TODO: Text transform sentence case only (javascript)
    font-size: px2em(16);
    line-height: px2em(22);
    //color: black, grey-dark;
}

%homepage-carousel-banner-subheader{
    //TODO: Text transform sentence case only (javascript)
    font-family: adelle_sans_semibold;
    font-size: px2em(21);
    line-height: px2em(26);
    @extend %white;
}

%dropdown-menu-text{
    //TODO: Text transform sentence case only (javascript)
    font-family: adelle_sans_regular;
    font-size: px2em(14);
    line-height: px2em(36);
    letter-spacing: 0.016em;;
    //color: black, grey-dark
}

%breadcrumbs{
    font-family: adelle_sans_regular;
    text-transform: uppercase;
    font-size: px2em(12);
    line-height: px2em(24);
    letter-spacing: 0.016em;
    //color: grey-darker, blue
}

%footer-copyright-line{
    //TODO: Text transform sentence case only (javascript)
    font-family: adelle_sans_regular;
    font-size: px2em(12);
    line-height: px2em(20);
    @extend %grey-darker;
}

%homepage-blurb-text-1{
    font-family: vollkorn_regular;
    font-size: px2em(20);
    line-height: px2em(9);
}

%homepage-blurb-text-2{
    font-family: 'proxima_nova_regular', arial, sans-serif;
    font-size: px2em(20);
    line-height: px2em(9);
}

.meta-error{
    font-weight: bold;
    color: red;
    background-color: orange;
}

%form-element {
    @extend %grey-light-bc;
    @extend %white-bg;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    height: 36px;
    padding-left: 11px;
    margin-bottom: 24px;
    padding-right: 11px;
    border-style: solid;
    border-width: 1px;
}

.input-hidden{
    display: none;
}
