.svg-image {
  display: inline-block;
  background-size: auto auto;
  background-repeat: no-repeat;
}

.svgi-default {
  width: 120px;
  height: 120px;
}

.svg-30 {
  width: 30px;
  height: 30px;
}

.svg-130 {
  width: 130px;
  height: 130px;
  background-size: cover;
}

.svg-140 {
  width: 140px;
  height: 140px;
}

.svg-160 {
  width: 160px;
  height: 160px;
}

.svg-180 {
  width: 180px;
  height: 180px;
}

.svg-200 {
  width: 200px;
  height: 200px;
}

.svg-220 {
  width: 220px;
  height: 220px;
}
