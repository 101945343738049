.recent-articles-slider {
  padding-top: 48px;
  padding-bottom: 42px;
  @media only screen and (min-width: 768px) {
    padding-top: 56px;
    padding-bottom: 14px;
  }
  .narrow-row {
    max-width: 1182px;
    margin: 0 auto;
    padding-left: 25px;
  }
  .slick-list {
    width: 93%;
    max-width: 1173px;
    padding-left: 24px;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-slider {
    margin-bottom: 0;
  }
  %slick-arrow {
    @extend %grey-lightest-bg;
    z-index: 50;
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 50%;
    top: 134px;
    @media only screen and (min-width: 375px) {
      top: 182px;
    }

    &:hover {
      background-color: #666;
    }
    &:before {
      content: '';
      background-color: transparent;
    }
  }
  .slick-prev{
    @extend %slick-arrow;
    @include svg-png-bkg('../images/page-left');
    left: 30px;
    &:hover {
      @include svg-png-bkg('../images/white-page-left');
    }
  }

  .slick-next{
    @extend %slick-arrow;
    @include svg-png-bkg('../images/page-right');
    right: 30px;
    &:hover {
      @include svg-png-bkg('../images/white-page-right');
    }
  }
  .headline-viewall{
    width: 100%;
    margin-bottom: 42px;
    padding-left: 0;
    clear: both;
    h4{
      margin: 0px;
      padding-left: 0px;
    }

    .view-all-container{
      text-align: right;
      padding-right: 12px;
      a{
        @extend %text-links-1;
        @extend %blue;
      }
    }
  }

  .article-tile{
    vertical-align: top;
    overflow: hidden;
    margin-bottom: 0;
    margin-right: 24px;
    outline: none;
    padding: 0 16px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 42px;
      padding: 0;
    }
    &:active, &:visited {
      outline: none;
    }
    img{
      width: 100%;
      margin-bottom: 24px;
      height: auto;
    }

    .headline-date{
      display: inline-block;
      width: 100%;
      padding-left: 0;
      h6{
        margin-top: 3px;
        font-size: px2em(22);
        line-height: 28px;
        font-family: 'vollkorn_regular', Georgia, serif;
        color: #3f3f3f;
        a {
          font-size: 1em;
          font-family: 'vollkorn_regular', Georgia, serif;
          color: #3f3f3f;
          &:hover {
            color: #000;
          }
        }
      }

      .datecat{
        .date{
          font-family: 'proxima_nova_regular', arial, sans-serif;
          font-size: px2em(13);
          line-height: 19px;
          text-transform: uppercase;
          color: #3f3f3f;
        }
      }
    }

    .excerpt-link {
      width: 100%;
      padding-left: 0;
    }

    p{
      @extend %grey-dark;
      font-family: 'proxima_nova_regular', arial, sans-serif;
      font-size: px2em(15);
      &.excerpt {
        padding-bottom: 2px;
      }
    }

    a{
      @extend %text-links-1;
      @extend %blue;
    }
  }
}
