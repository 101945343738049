// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Purpose: Implement styles used to render the paging buttons

.paging-buttons{
  padding-bottom: 95px;

  a{
    font-family: adelle_sans_semibold;
    @extend %white;
    @extend %blue-bg;

    width: auto;

    height: 36px;

    display: inline-block;

    text-align: center;
    line-height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: px2em(14);
  }

  a:hover{
    @extend %white;
    background-color: #3f3f3f;
  }

  a:visited{
    @extend %white;
  }

  .load-more{
    display: none;
    margin-right: 9px;
    text-transform: uppercase;
    font-size: px2em(14);
  }

  .load-all{
    text-transform: uppercase;
    font-size: px2em(14);
  }
}
