.practice-main {
  padding-bottom: 6px;
  @media only screen and (min-width: 480px) {
    padding-bottom: 54px;
  }

  h1 {
    padding-bottom: 36px;
  }

  h2 {
    font-family: 'vollkorn_regular', Georgia, serif;
    font-size: px2em(50);
    line-height: 54px;
    @media only screen and (min-width: 480px) {
      font-size: px2em(56);
      line-height: 60px;
    }
  }
  .lead-text {
    font-family: 'vollkorn_regular';
    @extend %grey-dark2;
    font-size: px2em(20);
    line-height: 26px;
  }
  .body-copy {
    font-family: 'proxima_nova_regular', arial, sans-serif;
    @extend %grey-dark;
    font-size: px2em(15);
    line-height: 21px;
    margin-top: 16px !important;
    p {
      margin-bottom: 15px;
    }
    a {
      @extend %grey-dark;
      text-decoration: underline;
      font-family: 'proxima_nova_regular', arial, sans-serif;
      &:hover {
        @extend %blue;
      }
    }
  }
  img {
    padding-top: 30px;
  }
}

.image-svg-banner {
  padding-top: 5px;
  padding-bottom: 62px;
  @media only screen and (min-width: 640px) {
    margin-bottom: 44px;
  }
  .centered {
    max-width: 100%;
    margin: 0 auto;
    @media only screen and (min-width: 952px) {
      max-width: 952px;
    }
    @media only screen and (min-width: 1200px) {
      max-width: 1170px;
    }
  }
  .image-banner-header {
    text-align: center;
    max-width: 640px;
    margin: 0 auto;
    h1 {
      font-family: 'vollkorn_regular', Georgia, serif;
      font-size: px2em(34);
      line-height: 43px;
      color: #fff;
      padding: 0 16px;
      @media only screen and (min-width: 640px) {
        font-size: px2em(45);
        line-height: 48px;
        padding: 0;
      }
    }
  }
  .image-banner-subheader {
    text-align: center;
    font-family: 'adelle_sans_semibold';
    color: #fff;
    font-size: px2em(19);
    line-height: 24px;
  }

}
