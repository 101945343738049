// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Purpose: Implement basic styles for the imgcopynav components

.copy-nav-container{
  padding-bottom: 42px;

    .img-container{
      width: 100%;

  background-repeat: no-repeat !important;
  @media only screen and (min-width: 768px) {
    max-width: 480px;
  	max-height: 300px;
  }
    }

    .text-content{
  @media only screen and (min-width: 640px) {
    width: 70%;
  }

	h5{
	    font-size: px2em(24);
	    line-height: px2em(30);
	    text-transform: capitalize;
	    margin-top: 35px;
	    @extend %black;
	    margin-bottom: 6px;
	}

	p{
	    @extend %grey-dark;
	    font-family: 'proxima_nova_regular', arial, sans-serif;
	    font-size: px2em(15);
	    margin-bottom: 10px;
	}

	a{
	    @extend %text-links-1;
	    @extend %blue;
	}
    }
}
