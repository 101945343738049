.category-select-container{
  @extend %grey-bc;
  position: relative;
  display: block;
  margin-bottom: 16px;
  padding-top: 24px;
  border-top-style: dotted;
  border-top-width: 1px;
  width: 227px;
  height: 36px;
  font-family: 'adelle_sans_regular';
  &:hover {
    cursor: pointer;
  }
  &:after {
    z-index: 3;
    height: 36px;
    width: 36px;
    display: block;
    position: absolute;
    content: '';
    background: url('../images/nav-arrow-down.svg') no-repeat;
    background-position: calc(100% - 13px);
    border-left: 1px solid #f0f0f0;
    top: 0px;
    left: 190px;
    background-color: transparent;
  }
  &.dropdown-open {
    &:after {
      background: url('../images/nav-arrow-up.svg') no-repeat;
      background-position: calc(100% - 13px);
    }
  }
  .category-select {
    @extend %form-element;
    @extend %grey-bc;
    border-width: 1px;
    border-style: solid;
    appearance: none;
    border-radius: 0px;
    width: 225px;
    background: white;
    padding: 0;
    .category-select-item {
      z-index: 2;
      font-size: px2em(14);
      position: relative;
      border-left: 1px;
      border-right: 1px;
      border-style: solid;
      border-color: #f0f0f0;
      background: white;
      a {
        display: block;
        color: #3f3f3f;
        padding: 0 12px;
        line-height: 36px;
        &:hover {
          color: #000;
        }
      }
      &:hover {
        cursor: pointer;
      }
      &:first-of-type {
        border-left: 1px;
        border-bottom: 1px;
        border-right: 1px;
        border-style: solid;
        border-color: #f0f0f0;
      }
      &:nth-of-type(n+2) {
        display: none;
      }
      &:last-of-type {
        border-bottom: 1px;
        border-style: solid;
        border-color: #f0f0f0;
      }
    }
  }
}
