.hero-slider {

  .slick-slide, .hero-slide-inner-content {
    height: 300px;
    background-size: cover;
    @media only screen and (min-width: 460px) {
      height: 400px;
    }
    @media only screen and (min-width: 768px) {
      height: 600px;
    }
    @media only screen and (min-width: 1024px) {
      height: 750px;
    }
  }
  .hero-slide-inner-content {
    position: relative;
    padding-top: 88px;
    .col-xs-12 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    h1 {
      font-family: 'vollkorn_regular', Georgia, serif;
      font-size: px2em(32);
      line-height: 36px;
      color: white;
      text-align: center;
      max-width: 640px;
      margin: 0 auto;
      padding-bottom: 16px;
      @media only screen and (min-width: 640px) {
        font-size: px2em(48);
        line-height: 52px;
        padding-bottom: 32px;
      }
      @media only screen and (min-width: 1024px) {
        font-size: px2em(56);
        line-height: 60px;
        padding-bottom: 32px;
      }
    }
    h2 {
      font-family: 'adelle_sans_semibold';
      font-size: px2em(18);
      line-height: 24px;
      color: white;
      text-align: center;
      max-width: 986px;
      margin: 0 auto;
      padding-bottom: 20px;
      text-transform: none;
      @media only screen and (min-width: 640px) {
        font-size: px2em(21);
        line-height: 26px;
        padding-bottom: 40px;
      }
    }
    p {
      text-align: center;
    }
    a {
      display: inline-block;
      width: 130px;
      height: 36px;
      line-height: 36px;
      background-color: #1E93C3;
      text-transform: uppercase;
      text-align: center;
      color: white;
      font-size: px2em(14);
      &:hover {
        background-color: #3f3f3f;
      }
    }
  }
  .slick-dots {
    bottom: 0;
    button {
      &:before {
        content: '';
      }
    }
    li {
      filter: alpha(opacity=50);
    	opacity: 0.5;
      -moz-opacity:0.5;
      &.slick-active {
        background-color: white;
        filter: alpha(opacity=0);
      	opacity: 1;
        -moz-opacity: 1;
      }
    }
  }
}

.lawyer-slider {
  margin-top: 80px;
  .lawyer-slide-inner-content {
    margin: 0 auto;
    width: 100%;
    max-width: 952px;
    @media only screen and (max-width: 640px) {
      background-image: none !important;
    }
    @media only screen and (min-width: 1200px) {
      max-width: 1152px;
    }
    .left-text {
      padding-left: 30px;
      margin-top: 20px;
      margin-bottom: 38px;
      margin-right: 24px;
      border-right: 1px solid white;
      display: inline-block;
      width: 100%;
      vertical-align: top;
      @media only screen and (min-width: 768px) {
        width: 33%;
      }
      @media only screen and (min-width: 1200px) {
        width: 44%;
      }
      h1 {
        font-size: px2em(43);
        line-height: 46px;
        font-family: 'vollkorn_regular', Georgia, serif;;
        color: white;
        max-width: 315px;
      }
    }
    .right-text {
      padding-left: 30px;
      padding-top: 42px;
      padding-bottom: 32px;
      width: 100%;
      display: inline-block;
      @media only screen and (min-width: 768px) {
        width: 40%;
      }
      @media only screen and (min-width: 1200px) {
        width: 50%;
      }
      h2, h3 {
        font-size: px2em(18);
        line-height: 24px;
        font-family: 'vollkorn_regular', Georgia, serif;
        color: white;
        margin: 0;
        padding-bottom: 12px;
      }
      p {
        font-size: px2em(13);
        line-height: 21px;
        font-family: 'proxima_nova_regular', arial, sans-serif;
        color: white;
      }
    }
  }
  .slick-dots {
    li {
      button {
        background: #e6e6e6;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        &:before {
          content: '';
          background: #E6E6E6;
          height: 10px;
          width: 10px;
          border-radius: 50%;
        }
      }
      &.slick-active {
        button {
          background: #BDCCD4;
          &:before {
            background: #BDCCD4;
          }
        }
      }
    }
  }
}

.testimonial-slider {
  margin-bottom: 94px;
  background-color: #E7BC36;
  @media only screen and (min-width: 640px) {
    margin-bottom: 130px;
  }
  .slick-slide {
    padding: 46px 0;
    @media only screen and (min-width: 768px) {
      padding: 94px 0;
    }
    p {
      font-size: px2em(25);
      color: #FFF;
      line-height: 36px;
      margin-bottom: 36px;
      font-family: 'vollkorn_regular', Georgia, serif;
      width: 100%;
      margin: 0 auto;
      padding: 0 24px;
      max-width: 766px;
    }
  }
  .slick-dots {
    bottom: -55px;
    li {
      button {
        width: 10px;
        height: 10px;
        border: 1px solid gray;
        border-radius: 50%;
        background-color: #f2f2f2;
        &:before {
          content: '';
        }
      }
      &.slick-active {
        button {
          background-color: #bdccd4;
        }
      }
    }
  }
}
