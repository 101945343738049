// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Purpose: Global class rules for the recent articles content

.recent-articles{

    .article-date{
	@extend %paragraph-3;
    }

    .article-title{
	@extend %h6;
	font-size: px2em(22);
    }

    .article-text{
	@extend %grey-dark;
	font-family: 'proxima_nova_regular', arial, sans-serif;
	font-size: px2em(15);
    }
}
