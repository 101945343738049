// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Purpose: Implements css rules for the

.lawyer-info{
  @extend %white-bg;
  padding-top: 22px;
  padding-left: 0px;

  .contact-info{
    vertical-align: top;
    padding-left: 0px;

    h1{
      font-family: 'vollkorn_regular', Georgia, serif;
      font-size: px2em(50);
      line-height: 54px;
      @media only screen and (min-width: 480px) {
        font-size: px2em(56);
        line-height: 60px;
      }
    }

    h3{
      font-family: 'vollkorn_regular';
      font-size: px2em(43);
      @extend %black;
      margin-top: 0;
      margin-bottom: 20px;
      line-height: 66px;
    }

    .telephone{
      margin-bottom: 18px;
      font-family: 'vollkorn_regular';
      font-size: px2em(20);
      line-height: 30px;
      color: #3f3f3f;
      &:hover {
        color: #000;
      }

      a{
        font-family: 'vollkorn_regular';
        @extend %grey-dark2;
        cursor: pointer;
        line-height: 30px;
        &:hover {
          color: #000;
        }
      }

    }

    .fax{
      font-family: 'vollkorn_regular';
      font-size: px2em(20);
      @extend %grey-dark2;
      margin-bottom: 12px;
      line-height: 30px;
      color: #3F3F3F !important;
      &:hover {
        color: #000 !important;
      }
      a {
        color: #3F3F3F !important;
        font-family: 'vollkorn_regular';
        font-size: px2em(16);
        @extend %grey-dark2;
        margin-bottom: 12px;
        line-height: 30px;
        &:hover {
          color: #000 !important;
        }
      }
    }

    .email{
      font-family: 'vollkorn_regular';
      font-size: px2em(20);
      @extend %grey-dark2;
      padding-bottom: 20px;
      line-height: 30px;
      &:hover {
        color: #000;
      }
      a{
        font-family: 'vollkorn_regular';
        @extend %grey-dark2;
        cursor: pointer;
        line-height: 30px;
        &:hover {
          color: #000;
        }
      }
    }
    .category-select-container{
      margin-top: 16px;
      padding: 0px;
      width: 275px;
      height: 36px;
      border-style: none;
      margin-bottom: 48px;
      @media only screen and (min-width: 640px) {
        width: 312px;
      }
      @media only screen and (min-width: 768px) {
        margin-bottom: 96px;
      }
      &:after {
        left: 190px;
        @media only screen and (min-width: 640px) {
          left: 238px;
        }
      }
      ul {
        @media only screen and (min-width: 640px) {
          width: 312px;
          width: 275px;
        }
      }
      li {
        line-height: 36px;
        @media only screen and (min-width: 640px) {
          width: 312px;
          width: 275px;
        }
      }
    }
  }

  .background-info{
    vertical-align: top;
    padding-left: 0px;
    @media only screen and (min-width: 768px) {
      margin-top: 14px;
    }

    @media(min-width: 768px){
      padding-left: inherit;
    }

    span{
      @extend %paragraph-4;
      @extend %grey-dark2;
    }

    .background-info-line{
      font-family: 'vollkorn_regular';
      font-size: px2em(20);
      line-height: 24px;
      @extend %grey-dark;
      @extend %grey3-bbc;
      border-bottom-style: dotted;
      border-bottom-width: 1px;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .links{
      margin-top: 20px;
      @media screen and (min-width: 768px){
        margin-top: 44px;
      }

      .link{
        @extend %blue;
        font-family: 'adelle_sans_regular';
        font-size: px2em(16);
        margin-right: 6px;
      }

      .shareBtn {
        display: inline-block;
        font-family: 'adelle_sans_regular';
        font-size: px2em(16px);
        cursor: pointer;
        position: relative;
        @extend %blue;
      }

    }
  }

    .social{
      margin-bottom: 34px;
      padding-left: 0px;
      padding-top: 24px;

      @media screen and (min-width: 768px){
        padding-top: 0px;
      }

      .facebook{
        @extend %soft-transition;
        @include svg-png-bkg('facebook');
        background-repeat: no-repeat;
        width: 44px;
        height: 48px;
        float: left;
        &:hover {
          @include svg-png-bkg('facebook-hover');
        }
      }

      .twitter{
        @extend %soft-transition;
        @include svg-png-bkg('twitter');
        background-repeat: no-repeat;
        width: 44px;
        height: 48px;
        float: left;
        &:hover {
          @include svg-png-bkg('twitter-hover');
        }
      }

      .linkedin{
        @extend %soft-transition;
        @include svg-png-bkg('linkedin');
        background-repeat: no-repeat;
        width: 44px;
        height: 48px;
        float: left;
        &:hover {
          @include svg-png-bkg('linkedin-hover');
        }
      }

      .gplus{
        @extend %soft-transition;
        @include svg-png-bkg('google_plus');
        background-repeat: no-repeat;
        width: 44px;
        height: 48px;
        float: left;
        &:hover {
          @include svg-png-bkg('google_plus_hover');
        }
      }
    }
  }
