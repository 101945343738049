/* Copyright 2016 Evoke Solutions

Author: Alan Dres

Implements the styles needed for the various pages.
*/


//Home/landing page
.home{
  .homepage-carousel{
    margin-bottom: 10px;
    @media only screen and (min-width: 480px) {
      margin-bottom: 58px;
    }


    li{
      @extend %white-bg;
      width: 60px !important;
      height: 6px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      @media only screen and (min-width: 640px) {
        width: 99px !important;
      }
    }

    li.vc_active{
      opacity: 1.0;
    }
  }

  .blurb{
    font-family: 'proxima_nova_regular';
    @extend %grey-dark;
    font-size: px2em(15);
    line-height: 21px;
  }


  .homepage-blurb{
    h2{
      font-family: 'vollkorn_regular';
      font-size: px2em(34);
      line-height: 37px;
      text-transform: capitalize;
      margin-bottom: 28px;
      margin-top: 0px;
      @media only screen and (min-width: 480px) {
        font-size: px2em(40);
        line-height: 43px;
      }
      @media only screen and (min-width: 768px) {
        font-size: px2em(45);
        line-height: 48px;
      }
    }

    .subtext1{
      font-size: px2em(20);
      line-height: 26px;
      font-family: 'vollkorn_regular';
      @extend %grey-dark2;
    }

    .hometext1{
      font-family: 'proxima_nova_regular';
      @extend %grey-dark;
      font-size: px2em(15);
      line-height: 21px;
      a {
        color: gray;
        text-decoration: underline;
        font-family: 'proxima_nova_regular', arial, sans-serif;
        &:hover {
          @extend %blue;
        }
      }
    }

  }

  .spacer{
    width: 100%;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 0;
    color: #f0f0f0;
    @media only screen and (min-width: 480px) {
      margin-top: 54px;
      margin-bottom: 48px;
    }

    .inner-space{
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 1px;
      border-bottom: 1px;
      border-style: solid;
    }
  }
  .testimonial {
    margin-bottom: 24px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 0;
    }
    .vc_column-inner {
      padding-left: 13px;
      @media only screen and (min-width: 768px) {
        padding-left: 15px;
      }
    }

    .content {
      padding-top: 54px;
      padding-bottom: 54px;
      &:after {
        border: 0;
      }
    }
  }
  .meet-team {
    margin-top: 5px;
    font-size: px2em(24);
    line-height: 30px;
    & + .blurb {
      font-size: px2em(15);
      line-height: 21px;
    }
  }
  .page-link{
    a{
      @extend %blue;
      font-family: adelle_sans_regular;
      font-size: px2em(16);
      margin-top: 23px;
    }

    a:visited{
      @extend %blue;
      font-family: adelle_sans_regular;
      font-size: px2em(16);
      margin-top: 23px;
    }

    a:hover{
      @extend %blue;
      font-family: adelle_sans_regular;
      font-size: px2em(16);
      margin-top: 23px;
    }
  }
}

.faq {
  @extend %grey-lighter-bg;

  h1{
    font-family: 'vollkorn_regular';
    font-size: px2em(56);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .lead-text{
    font-family: 'vollkorn_regular';
    @extend %grey-dark2;
    font-size: px2em(20);
    width: 100%;
  }

  .questions{
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;

    .left-block{
      display: inline-block;
      margin-right: 48px;
      vertical-align: top;

      @media (max-width: 1199px) {
        margin-right: 0;
      }


    }

    .right-block{
      display: inline-block;
      vertical-align: top;
    }

    @media (max-width: 564px) {
      // margin-left: 0;

      > div {
        width: 100%;

        > .question-block {
          width: 100%;
        }
      }
    }
  }

  .faq-banner-bg{
    @extend %white-bg;
    overflow: hidden;
  }

  .faq-banner-contents{
    @extend %white-bg;
    .left-side{
      display: inline-block;
      vertical-align: top;
      @media only screen and (min-width: 768px) {
        padding-bottom: 96px;
      }

      .headline{
        margin-top: 48px;
        font-family: 'vollkorn_regular';
        @extend %black;
        font-size: px2em(45);
        @media only screen and (min-width: 768px) {
          margin-top: 216px;
        }
      }

      .blurb{
        font-family: proxima_nova_regular;
        @extend %grey-dark;
        font-size: px2em(15);
        max-width: 100%;
        margin-top: 46px;
        @media only screen and (min-width: 768px) {
          max-width: 50%;
        }
      }

      .button{
        @extend %blue-bg;
        margin-top: 22px;
        width: 183px;
        height: 36px;
        text-transform: uppercase;
        text-align: center;
        &:hover {
          cursor: pointer;
        }

        a{
          width: 183px;
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          @extend %white;
        }

      }

      .button:hover{
        background-color: #3f3f3f;
      }
    }

    .right-side{
      display: inline-block;
      vertical-align: bottom;
      .faq-image{

        overflow: hidden;

        .image{
          max-width: 538px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

//Lawyer grid page
.our-lawyers, .licensed-law-clerks, .other-areas-of-law-lawyers, .personal-injury-lawyers {
  h1{
    font-family: 'vollkorn_regular';
    @extend %black;
    margin-top: 0px;
    font-size: px2em(50);
    line-height: 54px;
    @media only screen and (min-width: 480px) {
      font-size: px2em(56);
      line-height: 60px;
    }
  }

  .heading-text-block{
    font-family: 'vollkorn_regular';
    @extend %grey-dark2;
    font-size: px2em(20);
    line-height: 26px;
    margin-bottom: 0;
    padding-top: 6px;
  }

  .blurb-text-block{
    font-family: 'proxima_nova_regular';
    @extend %grey-dark;
    font-size: px2em(15);
    padding-top: 18px;
  }

  .spacer{
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 48px;
    color: #f0f0f0;

    .inner-space{
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 1px;
      border-bottom: 1px;
      border-style: solid;

    }
  }
}

.get-started, .our-firm {
  .link{
    a{
      @extend %blue;
      font-family: adelle_sans_regular;
      font-size: px2em(16);
      margin-top: 23px;
    }
    a:visited{
      @extend %blue;
      font-family: adelle_sans_regular;
      font-size: px2em(16);
      margin-top: 23px;
    }

    a:hover{
      @extend %blue;
      font-family: adelle_sans_regular;
      font-size: px2em(16);
      margin-top: 23px;
    }
  }

  .headline{
    text-align: center;
    line-height: 26px;

    h1{
      @extend %grey-dark2;
      font-family: 'vollkorn_regular';
      margin-left: auto;
      margin-right: auto;
      color: black;
    }

    .header-blurb{
      @extend %grey-dark2;
      font-family: 'vollkorn_regular';
      font-size: px2em(20);
      margin-left: auto;
      margin-right: auto;
      line-height: 26px;
      padding-top: 32px;
    }
  }

  .section-1{
    ol {
      list-style: decimal;
      list-style-position: inside;
    }
    ul {
      list-style: disc;
      list-style-position: inside;
      padding-left: 12px;
    }
    .header{
      font-family: 'vollkorn_regular';
      @extend %black;
      font-size: px2em(26);
      width: 100%;
      margin-bottom: 4px;
    }

    .left-column{
      .text{
        font-family: 'vollkorn_regular';
        @extend %grey-dark2;
        font-size: px2em(20);
        line-height: 26px;
      }

      .image{
        max-width: 525px;
        max-height: 373px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        margin-top: 52px;

        img {
          width: 100%;
          height: 100%;
          @media only screen and (min-width: 768px) {
            width: 100%;
            height: 100%;
            max-width: 525px;
            max-height: 373px;
          }
        }
      }
    }

    .right-column{
      .blurb{
        font-family: 'proxima_nova_regular';
        @extend %grey-dark;
        font-size: px2em(15);
      }

      .link{
        @extend %blue;
        font-family: 'adelle_sans_regular';
        font-size: px2em(16);
        margin-top: 23px;
      }
    }
  }

  .spacer{
    width: 100%;
    text-align: center;
    margin-top: 38px;
    margin-bottom: 32px;
    color: #f0f0f0;
    @media only screen and (min-width: 480px) {
      margin-top: 78px;
      margin-bottom: 80px;
    }

    .inner-space{
      margin-left: auto;
      margin-right: auto;
      height: 1px;
      border-bottom: 1px;
      border-style: solid;

    }
  }

  .section-2{
    .wpb_content_element {
      &.blurb {
        margin-bottom: 16px;
      }
    }
    h2 {
      margin-top: 0;
      margin-bottom: 10px;
    }
    .left-column{
      .header{
        font-family: 'vollkorn_regular';
        @extend %black;
        font-size: px2em(26);
        width: 100%;
      }

      .blurb{
        font-family: 'vollkorn_regular';
        @extend %grey-dark2;
        font-size: px2em(20);
        line-height: 26px;
      }
    }

    .right-column{
      position: relative;
      top: 10px;
      .blurb{
        p {
          font-family: 'proxima_nova_regular';
          @extend %grey-dark;
          font-size: px2em(15);
          line-height: 21px;
        }

      }
    }
  }

  .lawyer-banner {
    .banner-heading {
      padding-top: 18px;
      @media only screen and (min-width: 768px) {
        padding-top: 66px;
      }
    }
  }

  .section-3{
    padding-top: 48px;
    @media only screen and (min-width: 768px) {
      padding-top: 96px;
    }
    .left-column{

      .image{
        width: 506px;
        height: 346px;
        img{
          width: 506px;
          height: 346px;
        }
      }
    }

    .right-column{
      .header{
        font-family: 'vollkorn_regular';
        @extend %black;
        font-size: px2em(26);
        width: 100%;
        margin-bottom: 18px;
        line-height: 30px;
      }

      .headline{
        font-family: 'vollkorn_regular';
        @extend %grey-dark2;
        font-size: px2em(20);
        line-height: 26px;
        margin-bottom: 0;
      }

      .blurb{
        font-family: 'proxima_nova_regular';
        @extend %grey-dark;
        font-size: px2em(15);
      }
    }
  }

  .section-4{
    padding-top: 11px;
    .left-column {
      0
      .wpb_single_image {
        @media only screen and (min-width: 480px) {
          padding-top: 48px;
        }
      }
    }

    .testimonial {
      margin-bottom: 24px;
      @media only screen and (min-width: 480px) {
        margin-bottom: 0;
      }
    }

    .left-column, .right-column {

      .header{
        font-family: 'vollkorn_regular';
        @extend %black;
        font-size: px2em(26);
        margin-top: 0;
        margin-bottom: 6px;
        line-height: 30px;
      }

      .headline{
        font-family: 'vollkorn_regular';
        @extend %grey-dark2;
        font-size: px2em(20);
        text-align: left;
        margin-bottom: 0;
      }

      .blurb{
        font-family: 'proxima_nova_regular';
        @extend %grey-dark;
        font-size: px2em(15);
        margin-bottom: 42px;
        @media only screen and (min-width: 768px) {
          margin-bottom: 90px;
        }
      }
    }

  }

  .section-5{
    padding-top: 40px;
    @media only screen and (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    .left-column{
      padding-bottom: 20px;
      @media only screen and (min-width: 768px) {
        padding-bottom: 0;
      }
      .text {
        line-height: 26px;
      }
    }

    .right-column{
      .header{
        font-family: vollkorn_regular;
        @extend %grey-dark2;
        font-size: px2em(20);
        line-height: 26px;
      }

      .text{
        font-family: proxima_nova_regular;
        @extend %grey-dark;
        font-size: px2em(15);
        line-height: 21px;

      }
    }
  }

}

.practice-areas, .personal-injury-law, .other-areas-of-law {

  .left-header-block {
    position: relative;
    .category-select-container {

      bottom: 0;
      @media only screen and (min-width: 640px) {
        position: absolute;
        bottom: 48px;
      }
    }
  }

  .practices-grid {
    .vc_column-inner {
      padding: 0;
    }
  }

  .category-select-container {
    padding-top: 0;
    border: 0;
    margin-bottom: 0;
  }

  .text{
    display: inline-block;
    vertical-align: top;
    font-family: 'proxima_nova_regular';
    margin-bottom: 43px;
    @extend %grey-dark;
    font-size: px2em(15);
    @media only screen and (min-width: 768px) {
      margin-bottom: 93px;
    }
  }

  h1{
    @extend %h1;
    margin-top: 0px;
    font-size: px2em(45);
    line-height: 48px;
    padding-bottom: 75px;
  }

  .spacer{
    width: 100%;
    margin-top: 48px;
    margin-bottom: 24px;
    color: #f0f0f0;

    .inner-space{
      width: 100%;
      height: 1px;
      border-bottom: 1px;
      border-style: solid;
    }
  }

  .practice-grid{


    .practice-section {
      vertical-align: top;
    }

    .left-column{
      display: inline-block;
      vertical-align: top;
      margin-right: 24px;
      width: 31%;
      width: 100%;
      @media only screen and (min-width: 640px) {
        width: 45%;
      }
      @media only screen and (min-width: 850px) {
        width: 31%;
      }
    }

    .middle-column{
      display: inline-block;
      vertical-align: top;
      margin-right: 24px;
      width: 100%;
      @media only screen and (min-width: 640px) {
        width: 45%;
      }
      @media only screen and (min-width: 850px) {
        width: 31%;
      }
    }

    .right-column{
      display: inline-block;
      vertical-align: top;
      width: 100%;
      @media only screen and (min-width: 640px) {
        width: 45%;
      }
      @media only screen and (min-width: 850px) {
        width: 31%;
      }

    }
  }
  .spacer {
    margin-top: 0;
  }
}

.how-we-can-help-you{

  h1 {
    font-family: 'vollkorn_regular', Georgia, serif;
    font-size: px2em(50);
    line-height: 54px;
    @media only screen and (min-width: 480px) {
      font-size: px2em(56);
      line-height: 60px;
    }
    @media only screen and (min-width: 1024px) {
      max-width: 67%;
    }
  }

  .subheading{
    font-family: 'vollkorn_regular';
    @extend %grey-dark2;
    font-size: px2em(20);
    line-height: 26px;
    padding-top: 32px;
    padding-bottom: 8px;
  }

  .copy{
    font-family: 'proxima_nova_regular';
    @extend %grey-dark;
    font-size: px2em(15);
    margin-bottom: 14px;
    &.copy-right {
      max-width: 480px;
    }
  }

  .copy-image {
    @media only screen and (min-width: 768px) {
      position: relative;
      top: 32px;
    }
  }

  .yellow-bar{
    @extend %yellow-bg;
    padding: 42px 0 34px;
    margin-top: 18px;
    @media only screen and (min-width: 768px) {
      margin-top: 78px;
    }

    .containered-text{

      .left-side{
        display: inline-block;
        width: 100%;
        margin-right: 72px;
        vertical-align: top;
        @media only screen and (min-width: 640px) {
          width: 75%;
        }
        @media only screen and (min-width: 1024px) {
          width: 44%;
        }
        .lt{
          font-family: 'vollkorn_regular';
          @extend %white;
          font-size: px2em(20);
          line-height: 26px;
          @media only screen and (min-width: 1024px) {
            max-width: 400px;
          }
        }
      }

      .right-side{
        display: inline-block;
        width: 100%;
        @media only screen and (min-width: 640px) {
          width: 75%;
          max-width: auto;
        }
        @media only screen and (min-width: 1024px) {
          max-width: 480px;
        }

        .rt{
          font-family: 'proxima_nova_regular';
          @extend %black;
          font-size: px2em(15);
        }
        .rm{
          font-family: proxima_nova_regular;
          @extend %black;
          font-size: px2em(15);
        }
      }
    }
  }

  .contact-banner {
    .container {
      padding-left: 0;
    }
  }

  .faq-banner-contents{
    border-bottom: 1px solid #f0f0f0;
    @extend %white-bg;
    .left-side{
      display: inline-block;
      vertical-align: top;
      width: 100%;
      @media only screen and (min-width: 640px) {
        width: 42%;
      }

      .headline{
        margin-top: 40px;
        font-family: 'vollkorn_regular';
        @extend %black;
        font-size: px2em(40);
        line-height: 43px;
        @media only screen and (min-width: 640px) {
          font-size: px2em(45);
          line-height: 48px;
        }
        @media only screen and (min-width: 1024px) {
          margin-top: 136px;
        }
      }

      .blurb{
        font-family: 'proxima_nova_regular';
        @extend %grey-dark;
        font-size: px2em(15);
        margin-top: 46px;
        padding-bottom: 24px;
        width: 60%;
      }

      .button{
        @extend %blue-bg;
        margin-top: 22px;
        width: 183px;
        height: 36px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 96px;
        a{
          width: 183px;
          height: 36px;
          line-height: 36px;
          @extend %white;
          font-size: px2em(14);
        }

      }

      .button:hover{
        background-color: #3f3f3f;
        cursor: pointer;
      }
    }

    .right-side{
      vertical-align: bottom;
      display: inline-block;
      width: 100%;
      @media only screen and (min-width: 640px) {
        width: 50%;
      }
      .faq-image{
        overflow: hidden;

        .image{
          max-width: 400px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.no-left-padding{
  padding-left: 0px;
}

.meet-our-team {
  padding-top: 32px;
  padding-bottom: 42px;
}

.single {
  .category-select-container {
    .category-select {
      .category-select-item {
        &:first-of-type {
          line-height: 36px;
          padding-left: 12px;
        }
      }
    }
  }
}

.thank-you-text {
  padding-bottom: 52px;
  @media only screen and (min-width: 768px) {
    padding-bottom: 148px;
  }
  .heading {
    margin-top: 26px;
    margin-bottom: 22px;
    font-family: 'vollkorn_regular';
    font-size: px2em(26);
    line-height: px2em(30, 26);
    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
  }
  .subcopy {
    max-width: 585px;
    font-family: 'adelle_sans_regular';
    font-size: px2em(16);
    line-height: px2em(24, 16);
    p {
      margin-bottom: 40px;
    }
    a {
      color: black !important;
      font-family: 'adelle_sans_regular';
      font-size: px2em(16);
      line-height: px2em(24, 16);
      &:hover {
        color: black;
      }
      &:after {
        display: inline-block;
        @include svg-png-bkg('linkarrow');
        margin-left: 6px;
        content: '';
        width: 8px;
        height: 16px;
      }
      &.back {
        color: #1F93BC !important;
        &:hover {
          color: black;
        }
      }
    }
  }
}

.radio-413 {
  display: none;
}

.radio-label {
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 1px solid grey;
  border-radius: 50%;
  margin-bottom: 0;

  & + span {
    margin-left: 12px;
  }
}
