//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//
//Implements the sass required for the breadcrumbs<

.evbreadcrumbs {
	list-style: none;
	overflow: hidden;
	margin-top: 45px;
	padding-bottom: 10px;
	@media only screen and (min-width: 480px) {
		padding-bottom: 58px;
	}

	ul{
	    margin-bottom: 0px;
	}

	li{
	    display: inline-block;
	    margin-right: 8px;
			font-family: 'adelle_sans_regular';

	    a {
		@extend %breadcrumbs;
		@extend %blue;
		font-size: px2em(12);
		font-family: 'adelle_sans_regular';
	    }

	    a:hover{
		color: #3f3f3f;
	    }
			&.navigation-arrow {
				margin-left: 0;
			}
	}

	li:last-child{
	    @extend %black;
	    text-transform: uppercase;
	    font-size: px2em(12);
	}

	.separator{
	    font-size: 18px;
	    font-weight: 100;
	    color: #ccc;
			content: '';
			@include svg-png-bkg('linkarrow');
			background-position: center;
			position: relative;
			top: 1px;
	}
	.item-current {
		span {
			color: #3f3f3f;
		}
	}
}
