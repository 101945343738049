// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Implement the sharing functionality

.shareBtn{
    .holder {
	height: 36px;
  &:hover {
    color: #3f3f3f;
  }
    }

    .inner {
	background: #fff none repeat scroll 0 0;
	@extend %grey-lighter2-bc;
	border-width: 1px;
	border-style: solid;
	padding: 12px;
	position: absolute;
	top: 30px;
	width: 240px;
	z-index: 10;
	display: none;
    }

    .inner:after {
	     border-bottom: 10px solid #d9d9d9;
       border-right: 10px solid transparent;
       border-left: 10px solid transparent;
      content: '';
      height: 0px;
      width: 0px;
      bottom: 100%;
      position: absolute;
    }

    .inner:before {
	border-color: #3f3f3f;
	border-bottom-color: #000;
	border-width: 13px;
    }

    .inner ul {
	margin: 0;
	list-style: none;
    }

    .inner ul li {
	display: table;
	height: 36px;
	margin: 0;
	padding: 0;
	width: 100%;
    }

    .inner ul li:before {
	display: none;
    }

    .inner ul li a {
	@extend %white-bg;
	@extend %grey-dark2;
	font-family: 'adelle_sans_regular';
	font-size: px2em(14);
	height: 36px;
	width: 100%;
  line-height: 36px;
  &:hover {
    color: #000;
  }
    }

    .inner ul li a:hover{
	@extend %grey-dark2;
    }

    .inner ul li a i {
	font-size: px2em(22);
	margin: 0 12px 0 0;
	width: 15px;
    }

    .close-share {
      color: #3f3f3f;
      width: 10px;
      position: absolute;
      top: 3px;
      right: 6px;
      &:hover {
        color: #000;
      }
    }
}

.shareBtn:hover, .shareBtn.active {
    color: #fff;
}
