//Copyright 2016 Evoke Solutions
//Author: Alan Drees
//Purpose: Implements the styles needed for the share article widget

.single-post{
  .butthide{
    display: none;
  }

  .button-outer-class{
    .button-inner-class{
      button.article-share-button{
        @extend %white;
        @extend %blue-bg;
        height: 36px;
        width: 140px;
        border: none;
        padding-right: 12px;
        margin-bottom: 120px;
        text-align: right;
        background-image: url('../images/share-icon.svg');
        background-repeat: no-repeat;
        background-position: 12px;
        line-height: 32px;
        font-size: px2em(14);
        font-family: 'adelle_sans_regular', arial, sans-serif;
        &:hover {
          cursor: pointer;
          background-color: #3f3f3f;
        }
      }

      .shareBtn .inner{
	  left: 0px;
      }
    }
  }
}
