// Copyright 2016 Evoke Solutions
//
// Author: Alan Drees
//
// Purpose: Implement styles used by the Recent Articles Grid component

.recent-articles{
  padding-top: 57px;
  padding-bottom: 46px;

    .headline-viewall{
	width: 100%;
	margin-bottom: 42px;
  padding-left: 0;
	clear: both;
	h4{
	    margin: 0px;
	    padding-left: 0px;
	}

	.view-all-container{
	    text-align: right;
	    padding-right: 12px;
	    a{
		@extend %text-links-1;
		@extend %blue;
	    }
	}
    }

    .article-tile{
	vertical-align: top;
	overflow: hidden;
	margin-bottom: 42px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  @media only screen and (min-width: 640px) {
    width: 50%;
    padding-right: 15px;
  }
  @media only screen and (min-width: 1024px) {
    width: 33%;
    padding-right: 15px;
  }

	img{
	    width: 100%;
      margin-bottom: 24px;
	}

	.headline-date{
	    display: inline-block;
      width: 100%;
      padding-left: 0;
	    h6{
        margin-top: 3px;
		font-size: px2em(22);
    line-height: 28px;
    font-family: 'vollkorn_regular', Georgia, serif;
    color: #3f3f3f;
	    }

	    .datecat{
		.date{
		    font-family: 'proxima_nova_regular', arial, sans-serif;
		    font-size: px2em(13);
		    line-height: 19px;
		    text-transform: uppercase;
        color: #3f3f3f;
		}
	    }
	}

  .excerpt-link {
    width: 100%;
    padding-left: 0;
  }

	p{
	    @extend %grey-dark;
	    font-family: 'proxima_nova_regular', arial, sans-serif;
	    font-size: px2em(15);
      &.excerpt {
        padding-bottom: 2px;
      }
	}

	a{
	    @extend %text-links-1;
	    @extend %blue;
	}
    }

    .prev-nav{
	@extend %grey-lightest-bg;
	width: 45px;
	height: 45px;
	position: absolute;
	top: 374px;
	left: 0;
  background-image: url('../images/page-left.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 40%;
    }

    .next-nav{
	@extend %grey-lightest-bg;
	width: 45px;
	height: 45px;
	position: absolute;
	top: 374px;
	right: 0;
  background-image: url('../images/page-right.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 60%;
    }
}
