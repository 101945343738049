.privacy-policy-heading {
  h2 {
    max-width: 100%;
    font-size: px2em(50);
    line-height: 54px;
    padding-bottom: 32px;
    @media only screen and (min-width: 480px) {
      font-size: px2em(56);
      line-height: 60px;
    }
    @media only screen and (min-width: 1024px) {
      max-width: 67%;
    }
  }
  .lead-text, a {
    font-size: px2em(20);
    line-height: 26px;
    color: #3f3f3f;
    font-family: 'vollkorn_regular', Georgia, serif;
  }
  a {
    font-size: px2em(16);
    &:hover {
      color: #3f3f3f;
      cursor: default;
    }
  }
}

.privacy-policy-body {
  h3 {
    font-size: px2em(26);
    line-height: 26px;
    color: #3f3f3f;
    font-family: 'vollkorn_regular', Georgia, serif;
  }
}
