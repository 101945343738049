@each $cname, $chex in $colours {
    %#{$cname}     { color: #{$chex}; }
    %#{$cname}-bc  { border-color: #{$chex}; }
    %#{$cname}-bg  { background-color: #{$chex}; }
    %#{$cname}-btc { border-top-color: #{$chex}; }
    %#{$cname}-brc { border-right-color: #{$chex}; }
    %#{$cname}-blc { border-left-color: #{$chex}; }
    %#{$cname}-bbc { border-bottom-color: #{$chex}; }
    .#{$cname}     { color: #{$chex}; }
    .#{$cname}-bc  { border-color: #{$chex}; }
    .#{$cname}-bg  { background-color: #{$chex}; }
    .#{$cname}-btc { border-top-color: #{$chex}; }
    .#{$cname}-brc { border-right-color: #{$chex}; }
    .#{$cname}-blc { border-left-color: #{$chex}; }
    .#{$cname}-bbc { border-bottom-color: #{$chex}; }
}
