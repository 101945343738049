.text-content, .excerpt-link, .view-all-container, .link, .page-link {
  a {
    @extend %soft-transition;
    @include transition-soft();
    &:hover {
      color: #3f3f3f !important;
      @include transition-hard();
    }
  }
}

a {
  &.link {
    @extend %soft-transition;
    @include transition-soft();
    &:hover {
      color: #3f3f3f !important;
      @include transition-hard();
    }
  }
}

.inner-link-class {
  &.blue-button {
    a {
      font-size: px2em(14);
    }
  }
}
