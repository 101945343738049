// Copyright 2016 Evoke Solutions
// Author: Alan Drees
// Purpose: Implement SCSS styles for the article category overview page

.parent-pageid-477{

    .top-content{
	margin-top: 22px;
	margin-left: 0px;
    }

    .no-left-padding{
	.vc_column-inner{
	    padding-left: 0px;
	}
    }

    h1{
	font-family: 'vollkorn_regular';
	@extend %black;
	font-size: px2em(56);
	margin-top: 0px;
    }

    .blurb-text-block{
	font-family: 'proxima_nova_regular';
	@extend %grey-dark;
	font-size: px2em(15);
    }

    .filters{
	margin-top: 88px;

	.vc_col-sm-3 {
	    padding-left: 0px;
      width: 227px !important;
	}

	.article-search {
	    padding-left: 32px;
	    padding-bottom: 48px;
	    @media only screen and (min-width: 768px) {
		padding-bottom: 0;
	    }
	}

	.category-select-container{
	    padding: 0px;
	    border-style: none;
	}


	input[type='search']{
	    @extend %form-element;
	    display: inline-block;
	    width: 227px;
	    margin-bottom: 0px;
	}
  input[type='submit'] {
    @include svg-png-bkg('search-icon-mobile');
    width: 34px;
    height: 34px;
    background-position: center center;
    background-color: white;
    background-size: 16px 16px;
    border: 0;
    position: absolute;
    top: 1px;
    left: calc(100% + 27px);
    &:hover {
      @include svg-png-bkg('search-icon');
      background-position: center center;
      background-color: white;
      border: 0;
      background-size: 16px 16px;
    }
  }

	input::-webkit-input-placeholder {
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(14);
	}
	input:-moz-placeholder {
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(14);
	}
	input::-moz-placeholder {
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(14);
	}
	input:-ms-input-placeholder {
	    font-family: 'adelle_sans_regular';
	    font-size: px2em(14);
	}

    }
}

.article-search {
  .search-form {
    @include svg-png-bkg('search-icon-mobile');
    background-position: calc(100% - 12px);
    &:hover {
      @include svg-png-bkg('search-icon');
      background-position: calc(100% - 12px);
      background-size: 17px 17px;
    }
  }
}
